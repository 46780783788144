export interface RGBImage {
  red: number;
  green: number;
  blue: number;
  x: number;
  y: number;
  imageName: string;
}
export const dictionary: RGBImage[] = [
  { red: 133, green: 118, blue: 74, x: 0, y: 0, imageName: ':older_woman:' },
  {
    red: 93,
    green: 49,
    blue: 47,
    x: 0,
    y: 1,
    imageName: ':nail_care::skin-tone-4:',
  },
  { red: 229, green: 107, blue: 123, x: 0, y: 2, imageName: ':ab:' },
  {
    red: 107,
    green: 66,
    blue: 62,
    x: 0,
    y: 3,
    imageName: ':nail_care::skin-tone-2:',
  },
  {
    red: 50,
    green: 33,
    blue: 25,
    x: 0,
    y: 4,
    imageName: ':sign_of_the_horns::skin-tone-5:',
  },
  {
    red: 37,
    green: 38,
    blue: 39,
    x: 0,
    y: 5,
    imageName: ':weight_lifter::skin-tone-4:',
  },
  { red: 98, green: 68, blue: 138, x: 0, y: 6, imageName: ':octopus:' },
  {
    red: 98,
    green: 133,
    blue: 95,
    x: 0,
    y: 7,
    imageName: ':mountain_bicyclist::skin-tone-3:',
  },
  { red: 70, green: 85, blue: 87, x: 0, y: 8, imageName: ':medal:' },
  { red: 35, green: 66, blue: 91, x: 0, y: 9, imageName: ':notes:' },
  { red: 98, green: 139, blue: 150, x: 0, y: 10, imageName: ':map:' },
  {
    red: 149,
    green: 194,
    blue: 123,
    x: 0,
    y: 11,
    imageName: ':negative_squared_cross_mark:',
  },
  { red: 226, green: 160, blue: 71, x: 0, y: 12, imageName: ':family_wwgg:' },
  { red: 166, green: 133, blue: 55, x: 0, y: 13, imageName: ':clap:' },
  { red: 106, green: 164, blue: 208, x: 0, y: 14, imageName: ':play_pause:' },
  { red: 94, green: 96, blue: 100, x: 0, y: 15, imageName: ':stopwatch:' },
  { red: 46, green: 52, blue: 57, x: 0, y: 16, imageName: ':wrench:' },
  {
    red: 152,
    green: 157,
    blue: 170,
    x: 0,
    y: 17,
    imageName: ':bookmark_tabs:',
  },
  {
    red: 130,
    green: 92,
    blue: 63,
    x: 0,
    y: 18,
    imageName: ':fist::skin-tone-4:',
  },
  { red: 182, green: 145, blue: 54, x: 0, y: 19, imageName: ':weary:' },
  {
    red: 177,
    green: 143,
    blue: 134,
    x: 0,
    y: 20,
    imageName: ':ok_woman::skin-tone-2:',
  },
  {
    red: 49,
    green: 59,
    blue: 64,
    x: 0,
    y: 21,
    imageName: ':bicyclist::skin-tone-2:',
  },
  {
    red: 67,
    green: 47,
    blue: 32,
    x: 0,
    y: 22,
    imageName: ':hand_with_index_and_middle_finger_crossed::skin-tone-4:',
  },
  { red: 77, green: 67, blue: 41, x: 0, y: 23, imageName: ':man_in_tuxedo:' },
  { red: 100, green: 77, blue: 26, x: 0, y: 24, imageName: ':leopard:' },
  {
    red: 74,
    green: 49,
    blue: 36,
    x: 0,
    y: 25,
    imageName: ':thumbdown::skin-tone-5:',
  },
  { red: 131, green: 97, blue: 76, x: 0, y: 26, imageName: ':snail:' },
  {
    red: 91,
    green: 107,
    blue: 121,
    x: 0,
    y: 27,
    imageName: ':oncoming_police_car:',
  },
  { red: 207, green: 164, blue: 174, x: 0, y: 28, imageName: ':date:' },
  { red: 71, green: 84, blue: 93, x: 0, y: 29, imageName: ':bus:' },
  { red: 196, green: 154, blue: 84, x: 0, y: 30, imageName: ':flushed:' },
  { red: 75, green: 69, blue: 55, x: 0, y: 31, imageName: ':duck:' },
  {
    red: 119,
    green: 102,
    blue: 78,
    x: 0,
    y: 32,
    imageName: ':right_fist::skin-tone-2:',
  },
  { red: 123, green: 59, blue: 70, x: 0, y: 33, imageName: ':postbox:' },
  { red: 129, green: 111, blue: 124, x: 0, y: 34, imageName: ':flags:' },
  {
    red: 138,
    green: 108,
    blue: 80,
    x: 0,
    y: 35,
    imageName: ':clap::skin-tone-3:',
  },
  {
    red: 150,
    green: 124,
    blue: 103,
    x: 0,
    y: 36,
    imageName: ':no_good::skin-tone-2:',
  },
  {
    red: 81,
    green: 79,
    blue: 70,
    x: 0,
    y: 37,
    imageName: ':camera_with_flash:',
  },
  {
    red: 33,
    green: 47,
    blue: 48,
    x: 0,
    y: 38,
    imageName: ':golfer::skin-tone-5:',
  },
  {
    red: 122,
    green: 113,
    blue: 100,
    x: 0,
    y: 39,
    imageName: ':white_sun_cloud:',
  },
  {
    red: 88,
    green: 43,
    blue: 30,
    x: 0,
    y: 40,
    imageName: ':dancer::skin-tone-2:',
  },
  {
    red: 118,
    green: 159,
    blue: 190,
    x: 0,
    y: 41,
    imageName: ':baggage_claim:',
  },
  { red: 172, green: 120, blue: 30, x: 0, y: 42, imageName: ':sun_with_face:' },
  { red: 98, green: 147, blue: 145, x: 0, y: 43, imageName: ':earth_asia:' },
  { red: 176, green: 157, blue: 90, x: 0, y: 44, imageName: ':sob:' },
  {
    red: 76,
    green: 54,
    blue: 37,
    x: 0,
    y: 45,
    imageName: ':point_left::skin-tone-4:',
  },
  {
    red: 125,
    green: 148,
    blue: 164,
    x: 0,
    y: 46,
    imageName: ':speech_balloon:',
  },
  { red: 123, green: 93, blue: 49, x: 0, y: 47, imageName: ':city_dusk:' },
  { red: 206, green: 159, blue: 89, x: 0, y: 48, imageName: ':family_mwg:' },
  { red: 181, green: 115, blue: 94, x: 0, y: 49, imageName: ':hear_no_evil:' },
  { red: 145, green: 122, blue: 52, x: 1, y: 0, imageName: ':raised_hand:' },
  { red: 155, green: 165, blue: 171, x: 1, y: 1, imageName: ':clock5:' },
  {
    red: 156,
    green: 130,
    blue: 95,
    x: 1,
    y: 2,
    imageName: ':clap::skin-tone-2:',
  },
  { red: 100, green: 121, blue: 143, x: 1, y: 3, imageName: ':crystal_ball:' },
  { red: 24, green: 26, blue: 28, x: 1, y: 4, imageName: ':hole:' },
  {
    red: 106,
    green: 91,
    blue: 70,
    x: 1,
    y: 5,
    imageName: ':point_down::skin-tone-2:',
  },
  { red: 75, green: 110, blue: 51, x: 1, y: 6, imageName: ':green_apple:' },
  { red: 115, green: 130, blue: 140, x: 1, y: 7, imageName: ':card_index:' },
  {
    red: 246,
    green: 173,
    blue: 76,
    x: 1,
    y: 8,
    imageName: ':mobile_phone_off:',
  },
  { red: 196, green: 156, blue: 56, x: 1, y: 9, imageName: ':smirk:' },
  { red: 109, green: 142, blue: 166, x: 1, y: 10, imageName: ':computer:' },
  { red: 105, green: 104, blue: 35, x: 1, y: 11, imageName: ':corn:' },
  {
    red: 118,
    green: 101,
    blue: 78,
    x: 1,
    y: 12,
    imageName: ':left_fist::skin-tone-2:',
  },
  { red: 140, green: 96, blue: 30, x: 1, y: 13, imageName: ':honey_pot:' },
  { red: 157, green: 42, blue: 51, x: 1, y: 14, imageName: ':tomato:' },
  {
    red: 187,
    green: 136,
    blue: 130,
    x: 1,
    y: 15,
    imageName: ':santa::skin-tone-2:',
  },
  { red: 167, green: 109, blue: 117, x: 1, y: 16, imageName: ':white_flower:' },
  { red: 195, green: 164, blue: 85, x: 1, y: 17, imageName: ':sneezing_face:' },
  { red: 96, green: 138, blue: 154, x: 1, y: 18, imageName: ':sparkler:' },
  { red: 162, green: 38, blue: 56, x: 1, y: 19, imageName: ':rage:' },
  {
    red: 69,
    green: 45,
    blue: 34,
    x: 1,
    y: 20,
    imageName: ':raised_hand::skin-tone-5:',
  },
  { red: 103, green: 107, blue: 91, x: 1, y: 21, imageName: ':blossom:' },
  { red: 99, green: 110, blue: 117, x: 1, y: 22, imageName: ':wastebasket:' },
  { red: 113, green: 71, blue: 41, x: 1, y: 23, imageName: ':doughnut:' },
  {
    red: 94,
    green: 75,
    blue: 60,
    x: 1,
    y: 24,
    imageName: ':point_up_2::skin-tone-3:',
  },
  {
    red: 92,
    green: 74,
    blue: 59,
    x: 1,
    y: 25,
    imageName: ':point_right::skin-tone-3:',
  },
  {
    red: 88,
    green: 70,
    blue: 55,
    x: 1,
    y: 26,
    imageName: ':sign_of_the_horns::skin-tone-3:',
  },
  {
    red: 222,
    green: 192,
    blue: 132,
    x: 1,
    y: 27,
    imageName: ':bride_with_veil:',
  },
  { red: 95, green: 70, blue: 35, x: 1, y: 28, imageName: ':hammer_pick:' },
  { red: 111, green: 96, blue: 40, x: 1, y: 29, imageName: ':point_left:' },
  { red: 47, green: 35, blue: 66, x: 1, y: 30, imageName: ':closed_umbrella:' },
  { red: 73, green: 85, blue: 99, x: 1, y: 31, imageName: ':speedboat:' },
  { red: 38, green: 43, blue: 30, x: 1, y: 32, imageName: ':runner:' },
  { red: 107, green: 91, blue: 54, x: 1, y: 33, imageName: ':icecream:' },
  { red: 170, green: 130, blue: 79, x: 1, y: 34, imageName: ':haircut:' },
  { red: 105, green: 137, blue: 159, x: 1, y: 35, imageName: ':robot:' },
  { red: 186, green: 53, blue: 73, x: 1, y: 36, imageName: ':closed_book:' },
  { red: 161, green: 33, blue: 49, x: 1, y: 37, imageName: ':red_circle:' },
  {
    red: 68,
    green: 46,
    blue: 34,
    x: 1,
    y: 38,
    imageName: ':raised_hand_with_fingers_splayed::skin-tone-5:',
  },
  { red: 78, green: 88, blue: 74, x: 1, y: 39, imageName: ':cop:' },
  { red: 123, green: 68, blue: 79, x: 1, y: 40, imageName: ':fire_engine:' },
  {
    red: 27,
    green: 31,
    blue: 28,
    x: 1,
    y: 41,
    imageName: ':walking::skin-tone-3:',
  },
  {
    red: 145,
    green: 124,
    blue: 96,
    x: 1,
    y: 42,
    imageName: ':muscle::skin-tone-2:',
  },
  { red: 31, green: 38, blue: 49, x: 1, y: 43, imageName: ':paintbrush:' },
  {
    red: 103,
    green: 69,
    blue: 91,
    x: 1,
    y: 44,
    imageName: ':no_good::skin-tone-4:',
  },
  { red: 133, green: 172, blue: 165, x: 1, y: 45, imageName: ':motorway:' },
  { red: 121, green: 74, blue: 61, x: 1, y: 46, imageName: ':hotdog:' },
  {
    red: 105,
    green: 91,
    blue: 70,
    x: 1,
    y: 47,
    imageName: ':point_right::skin-tone-2:',
  },
  {
    red: 82,
    green: 58,
    blue: 40,
    x: 1,
    y: 48,
    imageName: ':ok_hand::skin-tone-4:',
  },
  { red: 215, green: 157, blue: 71, x: 1, y: 49, imageName: ':family_mwgg:' },
  { red: 194, green: 154, blue: 55, x: 2, y: 0, imageName: ':wink:' },
  { red: 137, green: 82, blue: 92, x: 2, y: 1, imageName: ':squid:' },
  { red: 110, green: 63, blue: 26, x: 2, y: 2, imageName: ':pizza:' },
  {
    red: 155,
    green: 106,
    blue: 110,
    x: 2,
    y: 3,
    imageName: ':cherry_blossom:',
  },
  { red: 111, green: 84, blue: 22, x: 2, y: 4, imageName: ':sunflower:' },
  {
    red: 58,
    green: 64,
    blue: 68,
    x: 2,
    y: 5,
    imageName: ':sleuth_or_spy::skin-tone-5:',
  },
  { red: 93, green: 50, blue: 38, x: 2, y: 6, imageName: ':dromedary_camel:' },
  { red: 86, green: 77, blue: 62, x: 2, y: 7, imageName: ':candle:' },
  { red: 93, green: 51, blue: 43, x: 2, y: 8, imageName: ':football:' },
  {
    red: 132,
    green: 105,
    blue: 81,
    x: 2,
    y: 9,
    imageName: ':thumbdown::skin-tone-3:',
  },
  {
    red: 69,
    green: 46,
    blue: 34,
    x: 2,
    y: 10,
    imageName: ':back_of_hand::skin-tone-5:',
  },
  { red: 72, green: 102, blue: 49, x: 2, y: 11, imageName: ':snake:' },
  { red: 48, green: 32, blue: 32, x: 2, y: 12, imageName: ':busstop:' },
  {
    red: 68,
    green: 107,
    blue: 132,
    x: 2,
    y: 13,
    imageName: ':oncoming_automobile:',
  },
  { red: 46, green: 59, blue: 44, x: 2, y: 14, imageName: ':motorcycle:' },
  { red: 123, green: 116, blue: 140, x: 2, y: 15, imageName: ':shaved_ice:' },
  {
    red: 99,
    green: 70,
    blue: 48,
    x: 2,
    y: 16,
    imageName: ':back_of_hand::skin-tone-4:',
  },
  { red: 102, green: 115, blue: 91, x: 2, y: 17, imageName: ':salad:' },
  { red: 35, green: 39, blue: 42, x: 2, y: 18, imageName: ':spoon:' },
  {
    red: 93,
    green: 74,
    blue: 58,
    x: 2,
    y: 19,
    imageName: ':open_hands::skin-tone-3:',
  },
  { red: 79, green: 87, blue: 92, x: 2, y: 20, imageName: ':poodle:' },
  { red: 190, green: 170, blue: 129, x: 2, y: 21, imageName: ':dividers:' },
  { red: 49, green: 58, blue: 26, x: 2, y: 22, imageName: ':ear_of_rice:' },
  {
    red: 191,
    green: 152,
    blue: 63,
    x: 2,
    y: 23,
    imageName: ':thermometer_face:',
  },
  { red: 156, green: 165, blue: 171, x: 2, y: 24, imageName: ':clock730:' },
  { red: 183, green: 153, blue: 69, x: 2, y: 25, imageName: ':cry:' },
  { red: 128, green: 132, blue: 123, x: 2, y: 26, imageName: ':oncoming_bus:' },
  { red: 105, green: 67, blue: 75, x: 2, y: 27, imageName: ':loudspeaker:' },
  { red: 76, green: 63, blue: 41, x: 2, y: 28, imageName: ':race_car:' },
  { red: 174, green: 108, blue: 87, x: 2, y: 29, imageName: ':see_no_evil:' },
  { red: 141, green: 101, blue: 43, x: 2, y: 30, imageName: ':taco:' },
  { red: 166, green: 130, blue: 212, x: 2, y: 31, imageName: ':ophiuchus:' },
  { red: 38, green: 72, blue: 99, x: 2, y: 32, imageName: ':droplet:' },
  {
    red: 159,
    green: 127,
    blue: 99,
    x: 2,
    y: 33,
    imageName: ':fist::skin-tone-3:',
  },
  {
    red: 129,
    green: 114,
    blue: 99,
    x: 2,
    y: 34,
    imageName: ':grandma::skin-tone-2:',
  },
  { red: 104, green: 132, blue: 153, x: 2, y: 35, imageName: ':printer:' },
  {
    red: 149,
    green: 101,
    blue: 127,
    x: 2,
    y: 36,
    imageName: ':ok_woman::skin-tone-3:',
  },
  { red: 41, green: 27, blue: 64, x: 2, y: 37, imageName: ':space_invader:' },
  { red: 200, green: 159, blue: 58, x: 2, y: 38, imageName: ':disappointed:' },
  { red: 193, green: 156, blue: 62, x: 2, y: 39, imageName: ':drooling_face:' },
  { red: 115, green: 152, blue: 180, x: 2, y: 40, imageName: ':mount_fuji:' },
  { red: 78, green: 108, blue: 87, x: 2, y: 41, imageName: ':beginner:' },
  {
    red: 21,
    green: 22,
    blue: 23,
    x: 2,
    y: 42,
    imageName: ':man_in_business_suit_levitating::skin-tone-4:',
  },
  { red: 118, green: 132, blue: 143, x: 2, y: 43, imageName: ':book:' },
  { red: 113, green: 57, blue: 56, x: 2, y: 44, imageName: ':tada:' },
  { red: 33, green: 53, blue: 21, x: 2, y: 45, imageName: ':turtle:' },
  {
    red: 146,
    green: 155,
    blue: 162,
    x: 2,
    y: 46,
    imageName: ':waning_gibbous_moon:',
  },
  {
    red: 68,
    green: 45,
    blue: 34,
    x: 2,
    y: 47,
    imageName: ':punch::skin-tone-5:',
  },
  { red: 190, green: 153, blue: 97, x: 2, y: 48, imageName: ':scroll:' },
  {
    red: 112,
    green: 106,
    blue: 66,
    x: 2,
    y: 49,
    imageName: ':two_men_holding_hands:',
  },
  {
    red: 156,
    green: 131,
    blue: 101,
    x: 3,
    y: 0,
    imageName: ':baby::skin-tone-2:',
  },
  {
    red: 94,
    green: 96,
    blue: 60,
    x: 3,
    y: 1,
    imageName: ':man_with_turban::skin-tone-2:',
  },
  { red: 91, green: 111, blue: 137, x: 3, y: 2, imageName: ':ferry:' },
  { red: 45, green: 52, blue: 56, x: 3, y: 3, imageName: ':gorilla:' },
  { red: 76, green: 54, blue: 60, x: 3, y: 4, imageName: ':pregnant_woman:' },
  {
    red: 134,
    green: 111,
    blue: 56,
    x: 3,
    y: 5,
    imageName: ':person_with_blond_hair::skin-tone-3:',
  },
  {
    red: 103,
    green: 91,
    blue: 84,
    x: 3,
    y: 6,
    imageName: ':sign_of_the_horns::skin-tone-1:',
  },
  { red: 197, green: 122, blue: 135, x: 3, y: 7, imageName: ':pig:' },
  {
    red: 93,
    green: 74,
    blue: 59,
    x: 3,
    y: 8,
    imageName: ':point_left::skin-tone-3:',
  },
  { red: 95, green: 56, blue: 18, x: 3, y: 9, imageName: ':guitar:' },
  { red: 127, green: 72, blue: 56, x: 3, y: 10, imageName: ':horse:' },
  {
    red: 161,
    green: 170,
    blue: 176,
    x: 3,
    y: 11,
    imageName: ':full_moon_with_face:',
  },
  { red: 184, green: 165, blue: 102, x: 3, y: 12, imageName: ':family_mmb:' },
  { red: 155, green: 113, blue: 43, x: 3, y: 13, imageName: ':trophy:' },
  {
    red: 92,
    green: 84,
    blue: 81,
    x: 3,
    y: 14,
    imageName: ':woman::skin-tone-1:',
  },
  { red: 113, green: 122, blue: 128, x: 3, y: 15, imageName: ':notebook:' },
  { red: 116, green: 135, blue: 128, x: 3, y: 16, imageName: ':stars:' },
  { red: 142, green: 111, blue: 68, x: 3, y: 17, imageName: ':couple:' },
  {
    red: 150,
    green: 127,
    blue: 98,
    x: 3,
    y: 18,
    imageName: ':thumbdown::skin-tone-2:',
  },
  {
    red: 193,
    green: 148,
    blue: 76,
    x: 3,
    y: 19,
    imageName: ':stuck_out_tongue_winking_eye:',
  },
  {
    red: 124,
    green: 127,
    blue: 130,
    x: 3,
    y: 20,
    imageName: ':black_square_button:',
  },
  { red: 106, green: 152, blue: 81, x: 3, y: 21, imageName: ':green_book:' },
  { red: 39, green: 83, blue: 116, x: 3, y: 22, imageName: ':fish:' },
  { red: 106, green: 91, blue: 38, x: 3, y: 23, imageName: ':middle_finger:' },
  { red: 114, green: 79, blue: 65, x: 3, y: 24, imageName: ':peanuts:' },
  { red: 21, green: 52, blue: 76, x: 3, y: 25, imageName: ':reminder_ribbon:' },
  { red: 108, green: 130, blue: 118, x: 3, y: 26, imageName: ':swimmer:' },
  { red: 99, green: 63, blue: 50, x: 3, y: 27, imageName: ':blowfish:' },
  {
    red: 122,
    green: 110,
    blue: 85,
    x: 3,
    y: 28,
    imageName: ':suspension_railway:',
  },
  {
    red: 61,
    green: 156,
    blue: 161,
    x: 3,
    y: 29,
    imageName: ':statue_of_liberty:',
  },
  { red: 164, green: 67, blue: 81, x: 3, y: 30, imageName: ':octagonal_sign:' },
  {
    red: 21,
    green: 28,
    blue: 26,
    x: 3,
    y: 31,
    imageName: ':runner::skin-tone-5:',
  },
  {
    red: 71,
    green: 24,
    blue: 25,
    x: 3,
    y: 32,
    imageName: ':dancer::skin-tone-4:',
  },
  { red: 160, green: 131, blue: 56, x: 3, y: 33, imageName: ':thumbsup:' },
  { red: 71, green: 50, blue: 34, x: 3, y: 34, imageName: ':v::skin-tone-4:' },
  { red: 56, green: 60, blue: 62, x: 3, y: 35, imageName: ':cow2:' },
  { red: 130, green: 103, blue: 36, x: 3, y: 36, imageName: ':crown:' },
  {
    red: 131,
    green: 87,
    blue: 100,
    x: 3,
    y: 37,
    imageName: ':raising_hand::skin-tone-3:',
  },
  {
    red: 23,
    green: 28,
    blue: 26,
    x: 3,
    y: 38,
    imageName: ':walking::skin-tone-4:',
  },
  {
    red: 71,
    green: 58,
    blue: 60,
    x: 3,
    y: 39,
    imageName: ':mailbox_with_no_mail:',
  },
  {
    red: 150,
    green: 127,
    blue: 97,
    x: 3,
    y: 40,
    imageName: ':thumbup::skin-tone-2:',
  },
  {
    red: 101,
    green: 72,
    blue: 75,
    x: 3,
    y: 41,
    imageName: ':haircut::skin-tone-4:',
  },
  { red: 152, green: 110, blue: 50, x: 3, y: 42, imageName: ':mosque:' },
  {
    red: 43,
    green: 29,
    blue: 53,
    x: 3,
    y: 43,
    imageName: ':expecting_woman::skin-tone-5:',
  },
  {
    red: 104,
    green: 87,
    blue: 43,
    x: 3,
    y: 44,
    imageName: ':construction_worker::skin-tone-5:',
  },
  { red: 225, green: 82, blue: 100, x: 3, y: 45, imageName: ':u7981:' },
  { red: 111, green: 167, blue: 209, x: 3, y: 46, imageName: ':track_next:' },
  {
    red: 102,
    green: 113,
    blue: 121,
    x: 3,
    y: 47,
    imageName: ':waxing_crescent_moon:',
  },
  { red: 143, green: 85, blue: 20, x: 3, y: 48, imageName: ':fox:' },
  {
    red: 95,
    green: 157,
    blue: 205,
    x: 3,
    y: 49,
    imageName: ':arrow_up_small:',
  },
  { red: 95, green: 63, blue: 45, x: 4, y: 0, imageName: ':man::skin-tone-4:' },
  {
    red: 97,
    green: 93,
    blue: 75,
    x: 4,
    y: 1,
    imageName: ':hourglass_flowing_sand:',
  },
  {
    red: 125,
    green: 111,
    blue: 103,
    x: 4,
    y: 2,
    imageName: ':call_me_hand::skin-tone-1:',
  },
  { red: 109, green: 114, blue: 118, x: 4, y: 3, imageName: ':milk:' },
  { red: 199, green: 129, blue: 69, x: 4, y: 4, imageName: ':heart_eyes:' },
  {
    red: 116,
    green: 107,
    blue: 102,
    x: 4,
    y: 5,
    imageName: ':prince::skin-tone-1:',
  },
  { red: 111, green: 118, blue: 122, x: 4, y: 6, imageName: ':toilet:' },
  {
    red: 32,
    green: 33,
    blue: 34,
    x: 4,
    y: 7,
    imageName: ':person_with_ball::skin-tone-5:',
  },
  {
    red: 137,
    green: 120,
    blue: 110,
    x: 4,
    y: 8,
    imageName: ':ear::skin-tone-1:',
  },
  {
    red: 107,
    green: 164,
    blue: 208,
    x: 4,
    y: 9,
    imageName: ':arrows_counterclockwise:',
  },
  { red: 87, green: 81, blue: 84, x: 4, y: 10, imageName: ':crossed_flags:' },
  {
    red: 17,
    green: 35,
    blue: 49,
    x: 4,
    y: 11,
    imageName: ':small_blue_diamond:',
  },
  { red: 67, green: 102, blue: 47, x: 4, y: 12, imageName: ':cactus:' },
  { red: 131, green: 179, blue: 216, x: 4, y: 13, imageName: ':stop_button:' },
  {
    red: 38,
    green: 48,
    blue: 32,
    x: 4,
    y: 14,
    imageName: ':selfie::skin-tone-3:',
  },
  { red: 105, green: 103, blue: 76, x: 4, y: 15, imageName: ':helicopter:' },
  { red: 140, green: 69, blue: 81, x: 4, y: 16, imageName: ':rotating_light:' },
  {
    red: 77,
    green: 89,
    blue: 92,
    x: 4,
    y: 17,
    imageName: ':rowboat::skin-tone-1:',
  },
  {
    red: 128,
    green: 87,
    blue: 116,
    x: 4,
    y: 18,
    imageName: ':ok_woman::skin-tone-4:',
  },
  { red: 114, green: 168, blue: 210, x: 4, y: 19, imageName: ':keycap_ten:' },
  { red: 244, green: 166, blue: 62, x: 4, y: 20, imageName: ':baby_symbol:' },
  { red: 99, green: 82, blue: 35, x: 4, y: 21, imageName: ':fingers_crossed:' },
  {
    red: 61,
    green: 17,
    blue: 20,
    x: 4,
    y: 22,
    imageName: ':dancer::skin-tone-5:',
  },
  {
    red: 110,
    green: 68,
    blue: 70,
    x: 4,
    y: 23,
    imageName: ':person_frowning::skin-tone-3:',
  },
  { red: 75, green: 84, blue: 83, x: 4, y: 24, imageName: ':tram:' },
  {
    red: 77,
    green: 55,
    blue: 38,
    x: 4,
    y: 25,
    imageName: ':point_up_2::skin-tone-4:',
  },
  { red: 97, green: 159, blue: 205, x: 4, y: 26, imageName: ':rewind:' },
  {
    red: 110,
    green: 116,
    blue: 112,
    x: 4,
    y: 27,
    imageName: ':performing_arts:',
  },
  {
    red: 78,
    green: 51,
    blue: 73,
    x: 4,
    y: 28,
    imageName: ':raising_hand::skin-tone-5:',
  },
  { red: 70, green: 64, blue: 64, x: 4, y: 29, imageName: ':gun:' },
  { red: 107, green: 98, blue: 82, x: 4, y: 30, imageName: ':tumbler_glass:' },
  { red: 137, green: 98, blue: 36, x: 4, y: 31, imageName: ':star2:' },
  {
    red: 96,
    green: 131,
    blue: 92,
    x: 4,
    y: 32,
    imageName: ':mountain_bicyclist::skin-tone-4:',
  },
  { red: 55, green: 62, blue: 67, x: 4, y: 33, imageName: ':microphone2:' },
  { red: 133, green: 87, blue: 47, x: 4, y: 34, imageName: ':lion_face:' },
  { red: 149, green: 184, blue: 227, x: 4, y: 35, imageName: ':tokyo_tower:' },
  {
    red: 39,
    green: 50,
    blue: 34,
    x: 4,
    y: 36,
    imageName: ':selfie::skin-tone-2:',
  },
  { red: 49, green: 55, blue: 59, x: 4, y: 37, imageName: ':shopping_cart:' },
  { red: 126, green: 46, blue: 58, x: 4, y: 38, imageName: ':game_die:' },
  {
    red: 125,
    green: 82,
    blue: 62,
    x: 4,
    y: 39,
    imageName: ':man::skin-tone-3:',
  },
  { red: 101, green: 161, blue: 206, x: 4, y: 40, imageName: ':abcd:' },
  {
    red: 38,
    green: 34,
    blue: 28,
    x: 4,
    y: 41,
    imageName: ':vertical_traffic_light:',
  },
  {
    red: 50,
    green: 32,
    blue: 23,
    x: 4,
    y: 42,
    imageName: ':man::skin-tone-5:',
  },
  { red: 78, green: 76, blue: 83, x: 4, y: 43, imageName: ':barber:' },
  { red: 136, green: 148, blue: 190, x: 4, y: 44, imageName: ':necktie:' },
  { red: 101, green: 108, blue: 80, x: 4, y: 45, imageName: ':railway_car:' },
  { red: 125, green: 57, blue: 82, x: 4, y: 46, imageName: ':shopping_bags:' },
  {
    red: 131,
    green: 75,
    blue: 54,
    x: 4,
    y: 47,
    imageName: ':woman::skin-tone-3:',
  },
  {
    red: 75,
    green: 66,
    blue: 59,
    x: 4,
    y: 48,
    imageName: ':pray::skin-tone-4:',
  },
  { red: 96, green: 93, blue: 52, x: 4, y: 49, imageName: ':man_with_turban:' },
  {
    red: 94,
    green: 156,
    blue: 204,
    x: 5,
    y: 0,
    imageName: ':twisted_rightwards_arrows:',
  },
  { red: 189, green: 151, blue: 60, x: 5, y: 1, imageName: ':grin:' },
  { red: 114, green: 149, blue: 93, x: 5, y: 2, imageName: ':frog:' },
  { red: 183, green: 139, blue: 52, x: 5, y: 3, imageName: ':girl:' },
  { red: 178, green: 117, blue: 107, x: 5, y: 4, imageName: ':alarm_clock:' },
  { red: 215, green: 165, blue: 89, x: 5, y: 5, imageName: ':family_wwbb:' },
  { red: 93, green: 46, blue: 52, x: 5, y: 6, imageName: ':no_mobile_phones:' },
  { red: 77, green: 78, blue: 82, x: 5, y: 7, imageName: ':bowling:' },
  { red: 102, green: 105, blue: 105, x: 5, y: 8, imageName: ':goat:' },
  {
    red: 72,
    green: 71,
    blue: 51,
    x: 5,
    y: 9,
    imageName: ':man_with_turban::skin-tone-4:',
  },
  { red: 152, green: 134, blue: 142, x: 5, y: 10, imageName: ':love_letter:' },
  { red: 56, green: 13, blue: 15, x: 5, y: 11, imageName: ':balloon:' },
  {
    red: 160,
    green: 131,
    blue: 106,
    x: 5,
    y: 12,
    imageName: ':raising_hand::skin-tone-2:',
  },
  { red: 152, green: 130, blue: 55, x: 5, y: 13, imageName: ':muscle:' },
  { red: 46, green: 68, blue: 85, x: 5, y: 14, imageName: ':shower:' },
  { red: 116, green: 98, blue: 41, x: 5, y: 15, imageName: ':nose:' },
  { red: 34, green: 33, blue: 29, x: 5, y: 16, imageName: ':speaking_head:' },
  { red: 151, green: 115, blue: 103, x: 5, y: 17, imageName: ':popcorn:' },
  {
    red: 40,
    green: 39,
    blue: 38,
    x: 5,
    y: 18,
    imageName: ':person_with_ball::skin-tone-4:',
  },
  { red: 59, green: 7, blue: 15, x: 5, y: 19, imageName: ':anger:' },
  { red: 244, green: 161, blue: 52, x: 5, y: 20, imageName: ':u55b6:' },
  { red: 51, green: 110, blue: 155, x: 5, y: 21, imageName: ':file_folder:' },
  { red: 124, green: 25, blue: 38, x: 5, y: 22, imageName: ':no_entry_sign:' },
  { red: 112, green: 70, blue: 13, x: 5, y: 23, imageName: ':croissant:' },
  { red: 117, green: 56, blue: 46, x: 5, y: 24, imageName: ':sweet_potato:' },
  {
    red: 108,
    green: 125,
    blue: 104,
    x: 5,
    y: 25,
    imageName: ':money_with_wings:',
  },
  { red: 122, green: 100, blue: 46, x: 5, y: 26, imageName: ':dancers:' },
  {
    red: 104,
    green: 74,
    blue: 51,
    x: 5,
    y: 27,
    imageName: ':muscle::skin-tone-4:',
  },
  {
    red: 102,
    green: 68,
    blue: 78,
    x: 5,
    y: 28,
    imageName: ':information_desk_person::skin-tone-4:',
  },
  { red: 236, green: 118, blue: 135, x: 5, y: 29, imageName: ':womens:' },
  { red: 62, green: 95, blue: 43, x: 5, y: 30, imageName: ':dragon:' },
  { red: 98, green: 62, blue: 42, x: 5, y: 31, imageName: ':confetti_ball:' },
  {
    red: 104,
    green: 83,
    blue: 66,
    x: 5,
    y: 32,
    imageName: ':right_fist::skin-tone-3:',
  },
  { red: 95, green: 79, blue: 67, x: 5, y: 33, imageName: ':tulip:' },
  {
    red: 131,
    green: 90,
    blue: 83,
    x: 5,
    y: 34,
    imageName: ':mother_christmas::skin-tone-4:',
  },
  {
    red: 133,
    green: 84,
    blue: 63,
    x: 5,
    y: 35,
    imageName: ':girl::skin-tone-3:',
  },
  { red: 141, green: 176, blue: 180, x: 5, y: 36, imageName: ':stadium:' },
  { red: 61, green: 69, blue: 74, x: 5, y: 37, imageName: ':bat:' },
  {
    red: 130,
    green: 111,
    blue: 104,
    x: 5,
    y: 38,
    imageName: ':angel::skin-tone-3:',
  },
  { red: 177, green: 158, blue: 97, x: 5, y: 39, imageName: ':fearful:' },
  { red: 84, green: 150, blue: 201, x: 5, y: 40, imageName: ':cool:' },
  { red: 94, green: 83, blue: 87, x: 5, y: 41, imageName: ':mailbox_closed:' },
  {
    red: 165,
    green: 74,
    blue: 76,
    x: 5,
    y: 42,
    imageName: ':sparkling_heart:',
  },
  {
    red: 121,
    green: 90,
    blue: 44,
    x: 5,
    y: 43,
    imageName: ':shallow_pan_of_food:',
  },
  { red: 103, green: 58, blue: 45, x: 5, y: 44, imageName: ':ox:' },
  {
    red: 101,
    green: 93,
    blue: 90,
    x: 5,
    y: 45,
    imageName: ':princess::skin-tone-1:',
  },
  { red: 34, green: 75, blue: 106, x: 5, y: 46, imageName: ':mega:' },
  {
    red: 62,
    green: 66,
    blue: 69,
    x: 5,
    y: 47,
    imageName: ':sleuth_or_spy::skin-tone-4:',
  },
  { red: 55, green: 103, blue: 141, x: 5, y: 48, imageName: ':sweat_drops:' },
  {
    red: 47,
    green: 58,
    blue: 67,
    x: 5,
    y: 49,
    imageName: ':cop::skin-tone-5:',
  },
  { red: 121, green: 64, blue: 56, x: 6, y: 0, imageName: ':outbox_tray:' },
  {
    red: 64,
    green: 69,
    blue: 72,
    x: 6,
    y: 1,
    imageName: ':sleuth_or_spy::skin-tone-3:',
  },
  { red: 64, green: 97, blue: 44, x: 6, y: 2, imageName: ':dragon_face:' },
  { red: 31, green: 35, blue: 47, x: 6, y: 3, imageName: ':scooter:' },
  { red: 84, green: 29, blue: 37, x: 6, y: 4, imageName: ':beetle:' },
  { red: 136, green: 182, blue: 217, x: 6, y: 5, imageName: ':cinema:' },
  {
    red: 88,
    green: 63,
    blue: 43,
    x: 6,
    y: 6,
    imageName: ':call_me_hand::skin-tone-4:',
  },
  { red: 84, green: 75, blue: 60, x: 6, y: 7, imageName: ':tools:' },
  {
    red: 75,
    green: 54,
    blue: 37,
    x: 6,
    y: 8,
    imageName: ':open_hands::skin-tone-4:',
  },
  { red: 184, green: 160, blue: 159, x: 6, y: 9, imageName: ':cow:' },
  { red: 91, green: 99, blue: 105, x: 6, y: 10, imageName: ':newspaper2:' },
  {
    red: 73,
    green: 31,
    blue: 35,
    x: 6,
    y: 11,
    imageName: ':dancer::skin-tone-1:',
  },
  { red: 32, green: 72, blue: 102, x: 6, y: 12, imageName: ':whale2:' },
  { red: 39, green: 45, blue: 49, x: 6, y: 13, imageName: ':video_camera:' },
  { red: 157, green: 166, blue: 172, x: 6, y: 14, imageName: ':clock12:' },
  { red: 171, green: 105, blue: 11, x: 6, y: 15, imageName: ':tangerine:' },
  { red: 51, green: 6, blue: 13, x: 6, y: 16, imageName: ':question:' },
  { red: 86, green: 97, blue: 106, x: 6, y: 17, imageName: ':film_frames:' },
  {
    red: 121,
    green: 97,
    blue: 76,
    x: 6,
    y: 18,
    imageName: ':back_of_hand::skin-tone-3:',
  },
  {
    red: 84,
    green: 102,
    blue: 116,
    x: 6,
    y: 19,
    imageName: ':swimmer::skin-tone-4:',
  },
  { red: 111, green: 95, blue: 40, x: 6, y: 20, imageName: ':point_right:' },
  { red: 79, green: 90, blue: 91, x: 6, y: 21, imageName: ':rowboat:' },
  {
    red: 29,
    green: 31,
    blue: 35,
    x: 6,
    y: 22,
    imageName: ':weight_lifter::skin-tone-5:',
  },
  { red: 187, green: 151, blue: 83, x: 6, y: 23, imageName: ':beer:' },
  { red: 197, green: 161, blue: 70, x: 6, y: 24, imageName: ':zipper_mouth:' },
  { red: 220, green: 180, blue: 93, x: 6, y: 25, imageName: ':scream_cat:' },
  { red: 108, green: 122, blue: 132, x: 6, y: 26, imageName: ':cloud_rain:' },
  {
    red: 87,
    green: 75,
    blue: 89,
    x: 6,
    y: 27,
    imageName: ':person_with_pouting_face::skin-tone-1:',
  },
  {
    red: 125,
    green: 80,
    blue: 88,
    x: 6,
    y: 28,
    imageName: ':information_desk_person::skin-tone-3:',
  },
  { red: 182, green: 145, blue: 55, x: 6, y: 29, imageName: ':smiley:' },
  {
    red: 110,
    green: 99,
    blue: 94,
    x: 6,
    y: 30,
    imageName: ':girl::skin-tone-1:',
  },
  {
    red: 53,
    green: 44,
    blue: 39,
    x: 6,
    y: 31,
    imageName: ':man_in_tuxedo::skin-tone-4:',
  },
  {
    red: 28,
    green: 33,
    blue: 29,
    x: 6,
    y: 32,
    imageName: ':runner::skin-tone-4:',
  },
  { red: 120, green: 77, blue: 30, x: 6, y: 33, imageName: ':bellhop:' },
  {
    red: 88,
    green: 62,
    blue: 50,
    x: 6,
    y: 34,
    imageName: ':horse_racing::skin-tone-4:',
  },
  { red: 39, green: 44, blue: 48, x: 6, y: 35, imageName: ':projector:' },
  { red: 58, green: 65, blue: 70, x: 6, y: 36, imageName: ':link:' },
  {
    red: 131,
    green: 113,
    blue: 49,
    x: 6,
    y: 37,
    imageName: ':construction_worker:',
  },
  {
    red: 85,
    green: 61,
    blue: 42,
    x: 6,
    y: 38,
    imageName: ':right_fist::skin-tone-4:',
  },
  {
    red: 148,
    green: 74,
    blue: 73,
    x: 6,
    y: 39,
    imageName: ':european_post_office:',
  },
  { red: 47, green: 81, blue: 106, x: 6, y: 40, imageName: ':tv:' },
  {
    red: 136,
    green: 119,
    blue: 87,
    x: 6,
    y: 41,
    imageName: ':wave::skin-tone-3:',
  },
  { red: 21, green: 24, blue: 26, x: 6, y: 42, imageName: ':back:' },
  {
    red: 201,
    green: 160,
    blue: 59,
    x: 6,
    y: 43,
    imageName: ':expressionless:',
  },
  {
    red: 31,
    green: 73,
    blue: 105,
    x: 6,
    y: 44,
    imageName: ':globe_with_meridians:',
  },
  {
    red: 77,
    green: 88,
    blue: 91,
    x: 6,
    y: 45,
    imageName: ':rowboat::skin-tone-3:',
  },
  { red: 159, green: 178, blue: 146, x: 6, y: 46, imageName: ':homes:' },
  { red: 177, green: 193, blue: 149, x: 6, y: 47, imageName: ':desert:' },
  { red: 82, green: 24, blue: 32, x: 6, y: 48, imageName: ':crayon:' },
  {
    red: 138,
    green: 108,
    blue: 85,
    x: 6,
    y: 49,
    imageName: ':baby::skin-tone-3:',
  },
  {
    red: 129,
    green: 112,
    blue: 69,
    x: 7,
    y: 0,
    imageName: ':construction_worker::skin-tone-1:',
  },
  {
    red: 62,
    green: 44,
    blue: 30,
    x: 7,
    y: 1,
    imageName: ':point_up::skin-tone-4:',
  },
  {
    red: 76,
    green: 87,
    blue: 90,
    x: 7,
    y: 2,
    imageName: ':rowboat::skin-tone-4:',
  },
  {
    red: 88,
    green: 103,
    blue: 116,
    x: 7,
    y: 3,
    imageName: ':bow::skin-tone-1:',
  },
  { red: 110, green: 99, blue: 63, x: 7, y: 4, imageName: ':kiwi:' },
  {
    red: 47,
    green: 56,
    blue: 63,
    x: 7,
    y: 5,
    imageName: ':bicyclist::skin-tone-3:',
  },
  {
    red: 149,
    green: 128,
    blue: 40,
    x: 7,
    y: 6,
    imageName: ':person_with_blond_hair:',
  },
  {
    red: 32,
    green: 38,
    blue: 30,
    x: 7,
    y: 7,
    imageName: ':walking::skin-tone-2:',
  },
  {
    red: 37,
    green: 51,
    blue: 51,
    x: 7,
    y: 8,
    imageName: ':golfer::skin-tone-3:',
  },
  { red: 68, green: 80, blue: 79, x: 7, y: 9, imageName: ':first_place:' },
  { red: 167, green: 145, blue: 70, x: 7, y: 10, imageName: ':older_man:' },
  { red: 95, green: 106, blue: 121, x: 7, y: 11, imageName: ':ship:' },
  { red: 191, green: 158, blue: 73, x: 7, y: 12, imageName: ':smile_cat:' },
  { red: 59, green: 68, blue: 73, x: 7, y: 13, imageName: ':shark:' },
  { red: 100, green: 62, blue: 43, x: 7, y: 14, imageName: ':meat_on_bone:' },
  {
    red: 180,
    green: 153,
    blue: 161,
    x: 7,
    y: 15,
    imageName: ':calendar_spiral:',
  },
  { red: 70, green: 70, blue: 62, x: 7, y: 16, imageName: ':bee:' },
  { red: 122, green: 133, blue: 141, x: 7, y: 17, imageName: ':floppy_disk:' },
  {
    red: 120,
    green: 108,
    blue: 102,
    x: 7,
    y: 18,
    imageName: ':boy::skin-tone-1:',
  },
  {
    red: 120,
    green: 107,
    blue: 99,
    x: 7,
    y: 19,
    imageName: ':left_fist::skin-tone-1:',
  },
  {
    red: 97,
    green: 69,
    blue: 48,
    x: 7,
    y: 20,
    imageName: ':raised_hand_with_fingers_splayed::skin-tone-4:',
  },
  { red: 150, green: 176, blue: 193, x: 7, y: 21, imageName: ':foggy:' },
  { red: 104, green: 55, blue: 41, x: 7, y: 22, imageName: ':boot:' },
  { red: 112, green: 155, blue: 188, x: 7, y: 23, imageName: ':left_luggage:' },
  {
    red: 135,
    green: 83,
    blue: 64,
    x: 7,
    y: 24,
    imageName: ':princess::skin-tone-3:',
  },
  { red: 121, green: 90, blue: 42, x: 7, y: 25, imageName: ':lock:' },
  { red: 9, green: 11, blue: 12, x: 7, y: 26, imageName: ':dark_sunglasses:' },
  {
    red: 25,
    green: 18,
    blue: 18,
    x: 7,
    y: 27,
    imageName: ':male_dancer::skin-tone-3:',
  },
  { red: 93, green: 156, blue: 204, x: 7, y: 28, imageName: ':up:' },
  {
    red: 145,
    green: 123,
    blue: 74,
    x: 7,
    y: 29,
    imageName: ':person_with_pouting_face::skin-tone-2:',
  },
  { red: 83, green: 136, blue: 175, x: 7, y: 30, imageName: ':potable_water:' },
  { red: 112, green: 93, blue: 39, x: 7, y: 31, imageName: ':open_hands:' },
  { red: 127, green: 53, blue: 64, x: 7, y: 32, imageName: ':factory:' },
  { red: 194, green: 164, blue: 89, x: 7, y: 33, imageName: ':head_bandage:' },
  { red: 191, green: 161, blue: 73, x: 7, y: 34, imageName: ':sweat:' },
  { red: 50, green: 62, blue: 50, x: 7, y: 35, imageName: ':wind_chime:' },
  { red: 195, green: 155, blue: 55, x: 7, y: 36, imageName: ':relieved:' },
  {
    red: 190,
    green: 141,
    blue: 58,
    x: 7,
    y: 37,
    imageName: ':stuck_out_tongue_closed_eyes:',
  },
  { red: 78, green: 132, blue: 172, x: 7, y: 38, imageName: ':wc:' },
  { red: 74, green: 43, blue: 34, x: 7, y: 39, imageName: ':racehorse:' },
  { red: 24, green: 28, blue: 31, x: 7, y: 40, imageName: ':end:' },
  { red: 172, green: 138, blue: 59, x: 7, y: 41, imageName: ':yellow_heart:' },
  { red: 115, green: 99, blue: 62, x: 7, y: 42, imageName: ':banana:' },
  {
    red: 147,
    green: 131,
    blue: 122,
    x: 7,
    y: 43,
    imageName: ':muscle::skin-tone-1:',
  },
  { red: 90, green: 96, blue: 100, x: 7, y: 44, imageName: ':anger_right:' },
  {
    red: 114,
    green: 98,
    blue: 75,
    x: 7,
    y: 45,
    imageName: ':ok_hand::skin-tone-2:',
  },
  { red: 64, green: 67, blue: 87, x: 7, y: 46, imageName: ':canoe:' },
  { red: 166, green: 105, blue: 44, x: 7, y: 47, imageName: ':fried_shrimp:' },
  {
    red: 133,
    green: 113,
    blue: 87,
    x: 7,
    y: 48,
    imageName: ':ear::skin-tone-2:',
  },
  {
    red: 193,
    green: 143,
    blue: 60,
    x: 7,
    y: 49,
    imageName: ':stuck_out_tongue:',
  },
  { red: 118, green: 105, blue: 78, x: 8, y: 0, imageName: ':burrito:' },
  {
    red: 74,
    green: 112,
    blue: 53,
    x: 8,
    y: 1,
    imageName: ':four_leaf_clover:',
  },
  { red: 58, green: 63, blue: 67, x: 8, y: 2, imageName: ':8ball:' },
  {
    red: 146,
    green: 123,
    blue: 52,
    x: 8,
    y: 3,
    imageName: ':raised_back_of_hand:',
  },
  { red: 76, green: 84, blue: 90, x: 8, y: 4, imageName: ':cloud_tornado:' },
  { red: 90, green: 66, blue: 56, x: 8, y: 5, imageName: ':military_medal:' },
  { red: 184, green: 152, blue: 88, x: 8, y: 6, imageName: ':beers:' },
  { red: 111, green: 23, blue: 34, x: 8, y: 7, imageName: ':maple_leaf:' },
  {
    red: 54,
    green: 20,
    blue: 26,
    x: 8,
    y: 8,
    imageName: ':small_red_triangle:',
  },
  {
    red: 121,
    green: 108,
    blue: 99,
    x: 8,
    y: 9,
    imageName: ':right_fist::skin-tone-1:',
  },
  { red: 30, green: 44, blue: 54, x: 8, y: 10, imageName: ':microphone:' },
  { red: 150, green: 127, blue: 52, x: 8, y: 11, imageName: ':raised_hands:' },
  { red: 121, green: 108, blue: 111, x: 8, y: 12, imageName: ':frame_photo:' },
  {
    red: 107,
    green: 96,
    blue: 89,
    x: 8,
    y: 13,
    imageName: ':point_left::skin-tone-1:',
  },
  { red: 150, green: 99, blue: 20, x: 8, y: 14, imageName: ':fire:' },
  { red: 118, green: 64, blue: 47, x: 8, y: 15, imageName: ':lipstick:' },
  { red: 63, green: 117, blue: 160, x: 8, y: 16, imageName: ':blue_heart:' },
  {
    red: 23,
    green: 24,
    blue: 25,
    x: 8,
    y: 17,
    imageName: ':man_in_business_suit_levitating::skin-tone-2:',
  },
  { red: 27, green: 31, blue: 33, x: 8, y: 18, imageName: ':water_buffalo:' },
  { red: 177, green: 150, blue: 72, x: 8, y: 19, imageName: ':sweat_smile:' },
  {
    red: 176,
    green: 198,
    blue: 214,
    x: 8,
    y: 20,
    imageName: ':chart_with_downwards_trend:',
  },
  { red: 149, green: 185, blue: 213, x: 8, y: 21, imageName: ':desktop:' },
  {
    red: 114,
    green: 79,
    blue: 54,
    x: 8,
    y: 22,
    imageName: ':baby::skin-tone-4:',
  },
  { red: 160, green: 97, blue: 13, x: 8, y: 23, imageName: ':basketball:' },
  {
    red: 107,
    green: 22,
    blue: 32,
    x: 8,
    y: 24,
    imageName: ':ideograph_advantage:',
  },
  { red: 127, green: 121, blue: 146, x: 8, y: 25, imageName: ':pound:' },
  { red: 202, green: 163, blue: 89, x: 8, y: 26, imageName: ':family_mwbb:' },
  { red: 149, green: 113, blue: 59, x: 8, y: 27, imageName: ':credit_card:' },
  { red: 58, green: 107, blue: 143, x: 8, y: 28, imageName: ':kimono:' },
  {
    red: 141,
    green: 125,
    blue: 115,
    x: 8,
    y: 29,
    imageName:
      ':raised_hand_with_part_between_middle_and_ring_fingers::skin-tone-1:',
  },
  {
    red: 156,
    green: 148,
    blue: 126,
    x: 8,
    y: 30,
    imageName: ':wave::skin-tone-1:',
  },
  { red: 105, green: 163, blue: 208, x: 8, y: 31, imageName: ':ng:' },
  {
    red: 159,
    green: 139,
    blue: 128,
    x: 8,
    y: 32,
    imageName: ':baby::skin-tone-1:',
  },
  { red: 172, green: 153, blue: 113, x: 8, y: 33, imageName: ':minidisc:' },
  { red: 109, green: 104, blue: 62, x: 8, y: 34, imageName: ':pray:' },
  { red: 153, green: 172, blue: 130, x: 8, y: 35, imageName: ':camping:' },
  {
    red: 171,
    green: 179,
    blue: 185,
    x: 8,
    y: 36,
    imageName: ':page_with_curl:',
  },
  {
    red: 63,
    green: 40,
    blue: 60,
    x: 8,
    y: 37,
    imageName: ':expecting_woman::skin-tone-3:',
  },
  { red: 121, green: 102, blue: 43, x: 8, y: 38, imageName: ':ok_hand:' },
  { red: 117, green: 116, blue: 118, x: 8, y: 39, imageName: ':ice_skate:' },
  {
    red: 142,
    green: 117,
    blue: 78,
    x: 8,
    y: 40,
    imageName: ':white_sun_small_cloud:',
  },
  { red: 73, green: 118, blue: 152, x: 8, y: 41, imageName: ':shield:' },
  { red: 205, green: 173, blue: 91, x: 8, y: 42, imageName: ':triumph:' },
  { red: 57, green: 62, blue: 65, x: 8, y: 43, imageName: ':nut_and_bolt:' },
  { red: 99, green: 139, blue: 152, x: 8, y: 44, imageName: ':fireworks:' },
  { red: 125, green: 114, blue: 111, x: 8, y: 45, imageName: ':rooster:' },
  {
    red: 82,
    green: 80,
    blue: 81,
    x: 8,
    y: 46,
    imageName: ':angel::skin-tone-5:',
  },
  {
    red: 108,
    green: 98,
    blue: 93,
    x: 8,
    y: 47,
    imageName: ':man::skin-tone-1:',
  },
  { red: 114, green: 44, blue: 55, x: 8, y: 48, imageName: ':two_hearts:' },
  { red: 106, green: 89, blue: 38, x: 8, y: 49, imageName: ':metal:' },
  {
    red: 123,
    green: 130,
    blue: 135,
    x: 9,
    y: 0,
    imageName: ':classical_building:',
  },
  {
    red: 97,
    green: 77,
    blue: 60,
    x: 9,
    y: 1,
    imageName: ':nose::skin-tone-3:',
  },
  { red: 113, green: 67, blue: 54, x: 9, y: 2, imageName: ':rice_cracker:' },
  {
    red: 82,
    green: 65,
    blue: 51,
    x: 9,
    y: 3,
    imageName: ':hand_with_index_and_middle_finger_crossed::skin-tone-3:',
  },
  { red: 164, green: 116, blue: 111, x: 9, y: 4, imageName: ':hibiscus:' },
  {
    red: 119,
    green: 113,
    blue: 103,
    x: 9,
    y: 5,
    imageName: ':cloud_lightning:',
  },
  {
    red: 76,
    green: 61,
    blue: 47,
    x: 9,
    y: 6,
    imageName: ':point_up::skin-tone-3:',
  },
  { red: 144, green: 119, blue: 127, x: 9, y: 7, imageName: ':calendar:' },
  {
    red: 54,
    green: 36,
    blue: 27,
    x: 9,
    y: 8,
    imageName: ':point_up_2::skin-tone-5:',
  },
  { red: 80, green: 42, blue: 33, x: 9, y: 9, imageName: ':deer:' },
  { red: 190, green: 139, blue: 50, x: 9, y: 10, imageName: ':woman:' },
  { red: 123, green: 89, blue: 144, x: 9, y: 11, imageName: ':handbag:' },
  { red: 124, green: 83, blue: 67, x: 9, y: 12, imageName: ':fallen_leaf:' },
  {
    red: 45,
    green: 49,
    blue: 50,
    x: 9,
    y: 13,
    imageName: ':person_with_ball::skin-tone-1:',
  },
  {
    red: 125,
    green: 106,
    blue: 44,
    x: 9,
    y: 14,
    imageName: ':right_facing_fist:',
  },
  { red: 156, green: 168, blue: 177, x: 9, y: 15, imageName: ':level_slider:' },
  { red: 157, green: 92, blue: 73, x: 9, y: 16, imageName: ':bear:' },
  { red: 127, green: 191, blue: 224, x: 9, y: 17, imageName: ':japan:' },
  {
    red: 24,
    green: 20,
    blue: 21,
    x: 9,
    y: 18,
    imageName: ':male_dancer::skin-tone-1:',
  },
  {
    red: 117,
    green: 120,
    blue: 123,
    x: 9,
    y: 19,
    imageName: ':angel::skin-tone-1:',
  },
  { red: 116, green: 95, blue: 39, x: 9, y: 20, imageName: ':handshake:' },
  { red: 124, green: 90, blue: 77, x: 9, y: 21, imageName: ':birthday:' },
  { red: 180, green: 111, blue: 86, x: 9, y: 22, imageName: ':gift_heart:' },
  { red: 55, green: 45, blue: 40, x: 9, y: 23, imageName: ':turkey:' },
  { red: 56, green: 49, blue: 33, x: 9, y: 24, imageName: ':kaaba:' },
  {
    red: 46,
    green: 44,
    blue: 43,
    x: 9,
    y: 25,
    imageName: ':person_with_ball::skin-tone-3:',
  },
  { red: 112, green: 107, blue: 96, x: 9, y: 26, imageName: ':light_rail:' },
  { red: 107, green: 74, blue: 72, x: 9, y: 27, imageName: ':pig_nose:' },
  { red: 195, green: 155, blue: 56, x: 9, y: 28, imageName: ':open_mouth:' },
  {
    red: 101,
    green: 105,
    blue: 103,
    x: 9,
    y: 29,
    imageName: ':bath::skin-tone-2:',
  },
  { red: 173, green: 147, blue: 92, x: 9, y: 30, imageName: ':tiger:' },
  {
    red: 129,
    green: 131,
    blue: 128,
    x: 9,
    y: 31,
    imageName: ':thunder_cloud_rain:',
  },
  {
    red: 148,
    green: 167,
    blue: 181,
    x: 9,
    y: 32,
    imageName: ':envelope_with_arrow:',
  },
  {
    red: 180,
    green: 131,
    blue: 90,
    x: 9,
    y: 33,
    imageName: ':couple_with_heart:',
  },
  {
    red: 127,
    green: 101,
    blue: 80,
    x: 9,
    y: 34,
    imageName: ':muscle::skin-tone-3:',
  },
  {
    red: 160,
    green: 139,
    blue: 110,
    x: 9,
    y: 35,
    imageName: ':older_man::skin-tone-2:',
  },
  { red: 198, green: 158, blue: 80, x: 9, y: 36, imageName: ':family_mmgb:' },
  { red: 190, green: 162, blue: 89, x: 9, y: 37, imageName: ':family_mmbb:' },
  { red: 152, green: 109, blue: 89, x: 9, y: 38, imageName: ':cookie:' },
  {
    red: 106,
    green: 89,
    blue: 61,
    x: 9,
    y: 39,
    imageName: ':lock_with_ink_pen:',
  },
  {
    red: 181,
    green: 154,
    blue: 219,
    x: 9,
    y: 40,
    imageName: ':six_pointed_star:',
  },
  { red: 65, green: 72, blue: 77, x: 9, y: 41, imageName: ':fork_and_knife:' },
  {
    red: 142,
    green: 125,
    blue: 114,
    x: 9,
    y: 42,
    imageName: ':punch::skin-tone-1:',
  },
  { red: 168, green: 133, blue: 213, x: 9, y: 43, imageName: ':om_symbol:' },
  { red: 78, green: 87, blue: 93, x: 9, y: 44, imageName: ':koala:' },
  {
    red: 51,
    green: 32,
    blue: 32,
    x: 9,
    y: 45,
    imageName: ':guardsman::skin-tone-4:',
  },
  {
    red: 120,
    green: 95,
    blue: 38,
    x: 9,
    y: 46,
    imageName: ':person_with_blond_hair::skin-tone-4:',
  },
  { red: 197, green: 156, blue: 56, x: 9, y: 47, imageName: ':upside_down:' },
  {
    red: 185,
    green: 164,
    blue: 151,
    x: 9,
    y: 48,
    imageName: ':fist::skin-tone-1:',
  },
  {
    red: 175,
    green: 152,
    blue: 78,
    x: 9,
    y: 49,
    imageName: ':boy::skin-tone-2:',
  },
  { red: 125, green: 175, blue: 214, x: 10, y: 0, imageName: ':restroom:' },
  {
    red: 159,
    green: 133,
    blue: 93,
    x: 10,
    y: 1,
    imageName: ':information_desk_person::skin-tone-2:',
  },
  {
    red: 89,
    green: 78,
    blue: 72,
    x: 10,
    y: 2,
    imageName: ':point_up::skin-tone-1:',
  },
  {
    red: 183,
    green: 192,
    blue: 199,
    x: 10,
    y: 3,
    imageName: ':fork_knife_plate:',
  },
  { red: 165, green: 137, blue: 58, x: 10, y: 4, imageName: ':baby:' },
  { red: 166, green: 145, blue: 57, x: 10, y: 5, imageName: ':lemon:' },
  {
    red: 168,
    green: 147,
    blue: 85,
    x: 10,
    y: 6,
    imageName: ':haircut::skin-tone-2:',
  },
  {
    red: 100,
    green: 80,
    blue: 30,
    x: 10,
    y: 7,
    imageName: ':children_crossing:',
  },
  {
    red: 143,
    green: 116,
    blue: 74,
    x: 10,
    y: 8,
    imageName: ':stuffed_flatbread:',
  },
  { red: 154, green: 155, blue: 156, x: 10, y: 9, imageName: ':panda_face:' },
  { red: 156, green: 59, blue: 38, x: 10, y: 10, imageName: ':fries:' },
  { red: 80, green: 71, blue: 58, x: 10, y: 11, imageName: ':dango:' },
  { red: 40, green: 43, blue: 46, x: 10, y: 12, imageName: ':dagger:' },
  { red: 98, green: 108, blue: 124, x: 10, y: 13, imageName: ':ski:' },
  { red: 56, green: 85, blue: 108, x: 10, y: 14, imageName: ':seat:' },
  {
    red: 80,
    green: 79,
    blue: 91,
    x: 10,
    y: 15,
    imageName: ':bridge_at_night:',
  },
  {
    red: 200,
    green: 160,
    blue: 58,
    x: 10,
    y: 16,
    imageName: ':kissing_smiling_eyes:',
  },
  { red: 81, green: 67, blue: 72, x: 10, y: 17, imageName: ':mute:' },
  { red: 197, green: 157, blue: 56, x: 10, y: 18, imageName: ':pensive:' },
  { red: 202, green: 175, blue: 104, x: 10, y: 19, imageName: ':mask:' },
  { red: 132, green: 101, blue: 29, x: 10, y: 20, imageName: ':cowboy:' },
  {
    red: 43,
    green: 29,
    blue: 21,
    x: 10,
    y: 21,
    imageName: ':point_up::skin-tone-5:',
  },
  {
    red: 106,
    green: 75,
    blue: 49,
    x: 10,
    y: 22,
    imageName: ':raised_hands::skin-tone-4:',
  },
  { red: 72, green: 65, blue: 77, x: 10, y: 23, imageName: ':rocket:' },
  { red: 28, green: 22, blue: 17, x: 10, y: 24, imageName: ':man_dancing:' },
  { red: 109, green: 68, blue: 48, x: 10, y: 25, imageName: ':nail_care:' },
  {
    red: 51,
    green: 34,
    blue: 25,
    x: 10,
    y: 26,
    imageName: ':reversed_hand_with_middle_finger_extended::skin-tone-5:',
  },
  {
    red: 85,
    green: 69,
    blue: 63,
    x: 10,
    y: 27,
    imageName: ':grandma::skin-tone-5:',
  },
  {
    red: 49,
    green: 43,
    blue: 43,
    x: 10,
    y: 28,
    imageName: ':writing_hand::skin-tone-5:',
  },
  { red: 195, green: 162, blue: 74, x: 10, y: 29, imageName: ':smiley_cat:' },
  { red: 105, green: 74, blue: 64, x: 10, y: 30, imageName: ':package:' },
  {
    red: 95,
    green: 90,
    blue: 79,
    x: 10,
    y: 31,
    imageName: ':writing_hand::skin-tone-2:',
  },
  {
    red: 19,
    green: 14,
    blue: 15,
    x: 10,
    y: 32,
    imageName: ':male_dancer::skin-tone-5:',
  },
  {
    red: 100,
    green: 86,
    blue: 66,
    x: 10,
    y: 33,
    imageName: ':sign_of_the_horns::skin-tone-2:',
  },
  {
    red: 153,
    green: 141,
    blue: 102,
    x: 10,
    y: 34,
    imageName: ':wave::skin-tone-2:',
  },
  {
    red: 112,
    green: 131,
    blue: 144,
    x: 10,
    y: 35,
    imageName: ':thought_balloon:',
  },
  { red: 56, green: 59, blue: 62, x: 10, y: 36, imageName: ':knife:' },
  { red: 68, green: 45, blue: 40, x: 10, y: 37, imageName: ':bow_and_arrow:' },
  { red: 83, green: 33, blue: 41, x: 10, y: 38, imageName: ':red_car:' },
  { red: 78, green: 28, blue: 29, x: 10, y: 39, imageName: ':hot_pepper:' },
  {
    red: 94,
    green: 59,
    blue: 62,
    x: 10,
    y: 40,
    imageName: ':steam_locomotive:',
  },
  {
    red: 138,
    green: 112,
    blue: 149,
    x: 10,
    y: 41,
    imageName: ':ok_woman::skin-tone-1:',
  },
  {
    red: 44,
    green: 53,
    blue: 60,
    x: 10,
    y: 42,
    imageName: ':bicyclist::skin-tone-4:',
  },
  { red: 184, green: 144, blue: 170, x: 10, y: 43, imageName: ':love_hotel:' },
  {
    red: 73,
    green: 78,
    blue: 80,
    x: 10,
    y: 44,
    imageName: ':first_quarter_moon_with_face:',
  },
  { red: 153, green: 114, blue: 94, x: 10, y: 45, imageName: ':no_good:' },
  { red: 194, green: 171, blue: 179, x: 10, y: 46, imageName: ':bookmark:' },
  {
    red: 106,
    green: 131,
    blue: 152,
    x: 10,
    y: 47,
    imageName: ':swimmer::skin-tone-1:',
  },
  { red: 85, green: 93, blue: 90, x: 10, y: 48, imageName: ':dove:' },
  { red: 117, green: 124, blue: 128, x: 10, y: 49, imageName: ':shell:' },
  { red: 133, green: 96, blue: 79, x: 11, y: 0, imageName: ':potato:' },
  {
    red: 35,
    green: 49,
    blue: 49,
    x: 11,
    y: 1,
    imageName: ':golfer::skin-tone-4:',
  },
  { red: 7, green: 8, blue: 8, x: 11, y: 2, imageName: ':heavy_minus_sign:' },
  { red: 98, green: 52, blue: 58, x: 11, y: 3, imageName: ':do_not_litter:' },
  { red: 97, green: 37, blue: 47, x: 11, y: 4, imageName: ':compression:' },
  {
    red: 141,
    green: 102,
    blue: 96,
    x: 11,
    y: 5,
    imageName: ':mother_christmas::skin-tone-3:',
  },
  { red: 132, green: 110, blue: 68, x: 11, y: 6, imageName: ':label:' },
  {
    red: 110,
    green: 37,
    blue: 44,
    x: 11,
    y: 7,
    imageName: ':helmet_with_cross:',
  },
  {
    red: 38,
    green: 53,
    blue: 54,
    x: 11,
    y: 8,
    imageName: ':golfer::skin-tone-1:',
  },
  {
    red: 190,
    green: 165,
    blue: 86,
    x: 11,
    y: 9,
    imageName: ':crying_cat_face:',
  },
  { red: 180, green: 126, blue: 60, x: 11, y: 10, imageName: ':kiss_mm:' },
  { red: 95, green: 121, blue: 137, x: 11, y: 11, imageName: ':cityscape:' },
  { red: 125, green: 142, blue: 122, x: 11, y: 12, imageName: ':euro:' },
  { red: 132, green: 142, blue: 149, x: 11, y: 13, imageName: ':bank:' },
  {
    red: 75,
    green: 60,
    blue: 61,
    x: 11,
    y: 14,
    imageName: ':expecting_woman::skin-tone-2:',
  },
  {
    red: 73,
    green: 52,
    blue: 36,
    x: 11,
    y: 15,
    imageName: ':reversed_hand_with_middle_finger_extended::skin-tone-4:',
  },
  { red: 89, green: 109, blue: 60, x: 11, y: 16, imageName: ':bamboo:' },
  { red: 46, green: 87, blue: 118, x: 11, y: 17, imageName: ':musical_note:' },
  { red: 95, green: 108, blue: 89, x: 11, y: 18, imageName: ':pager:' },
  {
    red: 76,
    green: 70,
    blue: 48,
    x: 11,
    y: 19,
    imageName: ':man_in_tuxedo::skin-tone-2:',
  },
  {
    red: 46,
    green: 31,
    blue: 23,
    x: 11,
    y: 20,
    imageName: ':hand_with_index_and_middle_finger_crossed::skin-tone-5:',
  },
  {
    red: 84,
    green: 91,
    blue: 95,
    x: 11,
    y: 21,
    imageName: ':wind_blowing_face:',
  },
  { red: 194, green: 161, blue: 69, x: 11, y: 22, imageName: ':sleepy:' },
  { red: 174, green: 142, blue: 216, x: 11, y: 23, imageName: ':id:' },
  { red: 79, green: 76, blue: 55, x: 11, y: 24, imageName: ':stew:' },
  {
    red: 137,
    green: 109,
    blue: 41,
    x: 11,
    y: 25,
    imageName: ':triangular_ruler:',
  },
  {
    red: 62,
    green: 125,
    blue: 174,
    x: 11,
    y: 26,
    imageName: ':large_blue_circle:',
  },
  { red: 169, green: 127, blue: 40, x: 11, y: 27, imageName: ':thinking:' },
  {
    red: 138,
    green: 123,
    blue: 114,
    x: 11,
    y: 28,
    imageName: ':raised_hand_with_fingers_splayed::skin-tone-1:',
  },
  {
    red: 87,
    green: 74,
    blue: 57,
    x: 11,
    y: 29,
    imageName: ':point_up::skin-tone-2:',
  },
  {
    red: 139,
    green: 118,
    blue: 90,
    x: 11,
    y: 30,
    imageName: ':punch::skin-tone-2:',
  },
  { red: 183, green: 145, blue: 55, x: 11, y: 31, imageName: ':laughing:' },
  {
    red: 100,
    green: 150,
    blue: 156,
    x: 11,
    y: 32,
    imageName: ':earth_americas:',
  },
  {
    red: 148,
    green: 109,
    blue: 66,
    x: 11,
    y: 33,
    imageName: ':person_frowning:',
  },
  { red: 108, green: 109, blue: 106, x: 11, y: 34, imageName: ':synagogue:' },
  { red: 76, green: 111, blue: 161, x: 11, y: 35, imageName: ':rosette:' },
  {
    red: 153,
    green: 184,
    blue: 217,
    x: 11,
    y: 36,
    imageName: ':ferris_wheel:',
  },
  {
    red: 197,
    green: 147,
    blue: 65,
    x: 11,
    y: 37,
    imageName: ':kissing_closed_eyes:',
  },
  { red: 66, green: 97, blue: 121, x: 11, y: 38, imageName: ':sake:' },
  { red: 99, green: 99, blue: 94, x: 11, y: 39, imageName: ':eagle:' },
  {
    red: 78,
    green: 84,
    blue: 67,
    x: 11,
    y: 40,
    imageName: ':man_with_turban::skin-tone-1:',
  },
  { red: 94, green: 42, blue: 48, x: 11, y: 41, imageName: ':lips:' },
  { red: 24, green: 28, blue: 30, x: 11, y: 42, imageName: ':on:' },
  {
    red: 163,
    green: 146,
    blue: 136,
    x: 11,
    y: 43,
    imageName: ':older_man::skin-tone-1:',
  },
  {
    red: 140,
    green: 124,
    blue: 115,
    x: 11,
    y: 44,
    imageName: ':raised_hand::skin-tone-1:',
  },
  {
    red: 154,
    green: 135,
    blue: 124,
    x: 11,
    y: 45,
    imageName: ':thumbdown::skin-tone-1:',
  },
  { red: 119, green: 65, blue: 85, x: 11, y: 46, imageName: ':cupid:' },
  {
    red: 105,
    green: 85,
    blue: 72,
    x: 11,
    y: 47,
    imageName: ':grandma::skin-tone-4:',
  },
  { red: 19, green: 22, blue: 24, x: 11, y: 48, imageName: ':ant:' },
  { red: 135, green: 80, blue: 29, x: 11, y: 49, imageName: ':no_bell:' },
  {
    red: 96,
    green: 99,
    blue: 102,
    x: 12,
    y: 0,
    imageName: ':bath::skin-tone-4:',
  },
  { red: 201, green: 140, blue: 64, x: 12, y: 1, imageName: ':couplekiss:' },
  { red: 144, green: 142, blue: 132, x: 12, y: 2, imageName: ':sheep:' },
  {
    red: 57,
    green: 38,
    blue: 28,
    x: 12,
    y: 3,
    imageName: ':ok_hand::skin-tone-5:',
  },
  { red: 171, green: 133, blue: 50, x: 12, y: 4, imageName: ':man:' },
  { red: 92, green: 48, blue: 48, x: 12, y: 5, imageName: ':bacon:' },
  { red: 76, green: 86, blue: 93, x: 12, y: 6, imageName: ':mag:' },
  {
    red: 59,
    green: 39,
    blue: 29,
    x: 12,
    y: 7,
    imageName: ':right_fist::skin-tone-5:',
  },
  {
    red: 28,
    green: 23,
    blue: 19,
    x: 12,
    y: 8,
    imageName: ':male_dancer::skin-tone-2:',
  },
  { red: 81, green: 55, blue: 45, x: 12, y: 9, imageName: ':dog2:' },
  { red: 47, green: 60, blue: 71, x: 12, y: 10, imageName: ':badminton:' },
  {
    red: 147,
    green: 166,
    blue: 201,
    x: 12,
    y: 11,
    imageName: ':roller_coaster:',
  },
  {
    red: 120,
    green: 96,
    blue: 76,
    x: 12,
    y: 12,
    imageName: ':raised_hand::skin-tone-3:',
  },
  { red: 72, green: 58, blue: 110, x: 12, y: 13, imageName: ':whale:' },
  {
    red: 78,
    green: 51,
    blue: 39,
    x: 12,
    y: 14,
    imageName: ':man_with_gua_pi_mao::skin-tone-5:',
  },
  { red: 60, green: 55, blue: 61, x: 12, y: 15, imageName: ':rat:' },
  {
    red: 73,
    green: 48,
    blue: 36,
    x: 12,
    y: 16,
    imageName: ':muscle::skin-tone-5:',
  },
  {
    red: 86,
    green: 108,
    blue: 108,
    x: 12,
    y: 17,
    imageName: ':surfer::skin-tone-2:',
  },
  {
    red: 130,
    green: 118,
    blue: 114,
    x: 12,
    y: 18,
    imageName: ':grandma::skin-tone-1:',
  },
  { red: 48, green: 15, blue: 9, x: 12, y: 19, imageName: ':footprints:' },
  {
    red: 60,
    green: 68,
    blue: 73,
    x: 12,
    y: 20,
    imageName: ':cop::skin-tone-4:',
  },
  { red: 157, green: 166, blue: 173, x: 12, y: 21, imageName: ':clock630:' },
  { red: 11, green: 12, blue: 14, x: 12, y: 22, imageName: ':curly_loop:' },
  { red: 133, green: 165, blue: 168, x: 12, y: 23, imageName: ':island:' },
  {
    red: 107,
    green: 69,
    blue: 71,
    x: 12,
    y: 24,
    imageName: ':nail_care::skin-tone-1:',
  },
  { red: 150, green: 31, blue: 47, x: 12, y: 25, imageName: ':ribbon:' },
  { red: 40, green: 71, blue: 95, x: 12, y: 26, imageName: ':blue_car:' },
  {
    red: 180,
    green: 126,
    blue: 123,
    x: 12,
    y: 27,
    imageName: ':santa::skin-tone-3:',
  },
  {
    red: 76,
    green: 51,
    blue: 38,
    x: 12,
    y: 28,
    imageName: ':baby::skin-tone-5:',
  },
  { red: 76, green: 35, blue: 24, x: 12, y: 29, imageName: ':field_hockey:' },
  { red: 49, green: 20, blue: 25, x: 12, y: 30, imageName: ':bike:' },
  {
    red: 89,
    green: 71,
    blue: 56,
    x: 12,
    y: 31,
    imageName: ':reversed_hand_with_middle_finger_extended::skin-tone-3:',
  },
  { red: 142, green: 117, blue: 49, x: 12, y: 32, imageName: ':ear:' },
  { red: 91, green: 67, blue: 126, x: 12, y: 33, imageName: ':grapes:' },
  { red: 96, green: 71, blue: 35, x: 12, y: 34, imageName: ':saxophone:' },
  { red: 87, green: 52, blue: 37, x: 12, y: 35, imageName: ':poultry_leg:' },
  { red: 163, green: 125, blue: 42, x: 12, y: 36, imageName: ':lying_face:' },
  { red: 33, green: 80, blue: 116, x: 12, y: 37, imageName: ':oil:' },
  {
    red: 19,
    green: 21,
    blue: 22,
    x: 12,
    y: 38,
    imageName: ':man_in_business_suit_levitating::skin-tone-5:',
  },
  { red: 113, green: 108, blue: 94, x: 12, y: 39, imageName: ':house:' },
  {
    red: 178,
    green: 153,
    blue: 71,
    x: 12,
    y: 40,
    imageName: ':girl::skin-tone-2:',
  },
  {
    red: 109,
    green: 90,
    blue: 100,
    x: 12,
    y: 41,
    imageName: ':massage::skin-tone-1:',
  },
  { red: 113, green: 137, blue: 154, x: 12, y: 42, imageName: ':ballot_box:' },
  { red: 94, green: 97, blue: 99, x: 12, y: 43, imageName: ':eyes:' },
  {
    red: 91,
    green: 60,
    blue: 45,
    x: 12,
    y: 44,
    imageName: ':fist::skin-tone-5:',
  },
  { red: 115, green: 121, blue: 124, x: 12, y: 45, imageName: ':chains:' },
  {
    red: 245,
    green: 171,
    blue: 72,
    x: 12,
    y: 46,
    imageName: ':vibration_mode:',
  },
  { red: 111, green: 123, blue: 128, x: 12, y: 47, imageName: ':fax:' },
  {
    red: 162,
    green: 120,
    blue: 82,
    x: 12,
    y: 48,
    imageName: ':information_desk_person:',
  },
  {
    red: 52,
    green: 35,
    blue: 10,
    x: 12,
    y: 49,
    imageName: ':small_orange_diamond:',
  },
  { red: 199, green: 158, blue: 58, x: 13, y: 0, imageName: ':kissing:' },
  { red: 70, green: 126, blue: 169, x: 13, y: 1, imageName: ':mens:' },
  {
    red: 141,
    green: 125,
    blue: 116,
    x: 13,
    y: 2,
    imageName: ':back_of_hand::skin-tone-1:',
  },
  {
    red: 105,
    green: 104,
    blue: 105,
    x: 13,
    y: 3,
    imageName: ':pray::skin-tone-1:',
  },
  { red: 106, green: 61, blue: 38, x: 13, y: 4, imageName: ':owl:' },
  { red: 86, green: 107, blue: 104, x: 13, y: 5, imageName: ':surfer:' },
  { red: 115, green: 70, blue: 58, x: 13, y: 6, imageName: ':beach_umbrella:' },
  {
    red: 114,
    green: 93,
    blue: 59,
    x: 13,
    y: 7,
    imageName: ':wave::skin-tone-4:',
  },
  {
    red: 81,
    green: 101,
    blue: 106,
    x: 13,
    y: 8,
    imageName: ':surfer::skin-tone-3:',
  },
  {
    red: 127,
    green: 100,
    blue: 76,
    x: 13,
    y: 9,
    imageName: ':raised_hands::skin-tone-3:',
  },
  { red: 77, green: 41, blue: 31, x: 13, y: 10, imageName: ':cat2:' },
  { red: 128, green: 137, blue: 152, x: 13, y: 11, imageName: ':skier:' },
  { red: 70, green: 79, blue: 51, x: 13, y: 12, imageName: ':tractor:' },
  { red: 94, green: 157, blue: 204, x: 13, y: 13, imageName: ':new:' },
  { red: 81, green: 120, blue: 60, x: 13, y: 14, imageName: ':green_heart:' },
  { red: 126, green: 111, blue: 102, x: 13, y: 15, imageName: ':egg:' },
  {
    red: 136,
    green: 187,
    blue: 107,
    x: 13,
    y: 16,
    imageName: ':white_check_mark:',
  },
  {
    red: 49,
    green: 59,
    blue: 67,
    x: 13,
    y: 17,
    imageName: ':bicyclist::skin-tone-1:',
  },
  { red: 98, green: 139, blue: 145, x: 13, y: 18, imageName: ':rice_scene:' },
  { red: 118, green: 48, blue: 48, x: 13, y: 19, imageName: ':japanese_ogre:' },
  {
    red: 67,
    green: 44,
    blue: 53,
    x: 13,
    y: 20,
    imageName: ':massage::skin-tone-5:',
  },
  {
    red: 116,
    green: 125,
    blue: 102,
    x: 13,
    y: 21,
    imageName: ':bow::skin-tone-2:',
  },
  { red: 92, green: 98, blue: 104, x: 13, y: 22, imageName: ':rabbit2:' },
  { red: 101, green: 161, blue: 207, x: 13, y: 23, imageName: ':ok:' },
  { red: 168, green: 164, blue: 119, x: 13, y: 24, imageName: ':sunrise:' },
  { red: 140, green: 116, blue: 98, x: 13, y: 25, imageName: ':spaghetti:' },
  {
    red: 12,
    green: 14,
    blue: 15,
    x: 13,
    y: 26,
    imageName: ':heavy_plus_sign:',
  },
  {
    red: 110,
    green: 80,
    blue: 63,
    x: 13,
    y: 27,
    imageName: ':man_with_gua_pi_mao::skin-tone-3:',
  },
  {
    red: 135,
    green: 116,
    blue: 90,
    x: 13,
    y: 28,
    imageName: ':raised_hand_with_fingers_splayed::skin-tone-2:',
  },
  {
    red: 58,
    green: 56,
    blue: 56,
    x: 13,
    y: 29,
    imageName: ':man_in_tuxedo::skin-tone-1:',
  },
  {
    red: 188,
    green: 174,
    blue: 75,
    x: 13,
    y: 30,
    imageName: ':sunrise_over_mountains:',
  },
  { red: 186, green: 154, blue: 66, x: 13, y: 31, imageName: ':sleeping:' },
  {
    red: 67,
    green: 44,
    blue: 61,
    x: 13,
    y: 32,
    imageName: ':information_desk_person::skin-tone-5:',
  },
  { red: 88, green: 57, blue: 49, x: 13, y: 33, imageName: ':cricket:' },
  {
    red: 87,
    green: 58,
    blue: 63,
    x: 13,
    y: 34,
    imageName: ':person_with_pouting_face::skin-tone-4:',
  },
  { red: 224, green: 207, blue: 203, x: 13, y: 35, imageName: ':fog:' },
  {
    red: 159,
    green: 166,
    blue: 171,
    x: 13,
    y: 36,
    imageName: ':martial_arts_uniform:',
  },
  {
    red: 16,
    green: 18,
    blue: 19,
    x: 13,
    y: 37,
    imageName: ':telephone_receiver:',
  },
  {
    red: 53,
    green: 42,
    blue: 66,
    x: 13,
    y: 38,
    imageName: ':expecting_woman::skin-tone-1:',
  },
  {
    red: 65,
    green: 52,
    blue: 45,
    x: 13,
    y: 39,
    imageName: ':prince::skin-tone-5:',
  },
  { red: 121, green: 141, blue: 156, x: 13, y: 40, imageName: ':dash:' },
  { red: 92, green: 113, blue: 73, x: 13, y: 41, imageName: ':pouch:' },
  { red: 49, green: 49, blue: 53, x: 13, y: 42, imageName: ':bed:' },
  { red: 83, green: 73, blue: 34, x: 13, y: 43, imageName: ':bouquet:' },
  { red: 114, green: 59, blue: 61, x: 13, y: 44, imageName: ':chocolate_bar:' },
  { red: 191, green: 165, blue: 82, x: 13, y: 45, imageName: ':innocent:' },
  { red: 228, green: 96, blue: 113, x: 13, y: 46, imageName: ':cl:' },
  { red: 120, green: 121, blue: 113, x: 13, y: 47, imageName: ':ram:' },
  { red: 80, green: 111, blue: 76, x: 13, y: 48, imageName: ':leaves:' },
  {
    red: 104,
    green: 128,
    blue: 138,
    x: 13,
    y: 49,
    imageName: ':swimmer::skin-tone-2:',
  },
  {
    red: 137,
    green: 60,
    blue: 73,
    x: 14,
    y: 0,
    imageName: ':revolving_hearts:',
  },
  {
    red: 53,
    green: 36,
    blue: 26,
    x: 14,
    y: 1,
    imageName: ':point_down::skin-tone-5:',
  },
  { red: 43, green: 99, blue: 142, x: 14, y: 2, imageName: ':shirt:' },
  {
    red: 101,
    green: 118,
    blue: 130,
    x: 14,
    y: 3,
    imageName: ':european_castle:',
  },
  {
    red: 140,
    green: 180,
    blue: 179,
    x: 14,
    y: 4,
    imageName: ':railway_track:',
  },
  { red: 35, green: 30, blue: 19, x: 14, y: 5, imageName: ':pen_ballpoint:' },
  { red: 106, green: 40, blue: 48, x: 14, y: 6, imageName: ':candy:' },
  { red: 197, green: 165, blue: 144, x: 14, y: 7, imageName: ':clown:' },
  {
    red: 121,
    green: 144,
    blue: 162,
    x: 14,
    y: 8,
    imageName: ':rugby_football:',
  },
  { red: 119, green: 120, blue: 88, x: 14, y: 9, imageName: ':bow:' },
  { red: 82, green: 102, blue: 57, x: 14, y: 10, imageName: ':avocado:' },
  { red: 47, green: 94, blue: 130, x: 14, y: 11, imageName: ':dress:' },
  { red: 50, green: 99, blue: 136, x: 14, y: 12, imageName: ':radio_button:' },
  { red: 99, green: 146, blue: 144, x: 14, y: 13, imageName: ':park:' },
  { red: 193, green: 124, blue: 88, x: 14, y: 14, imageName: ':gift:' },
  {
    red: 95,
    green: 92,
    blue: 97,
    x: 14,
    y: 15,
    imageName: ':bow::skin-tone-3:',
  },
  { red: 158, green: 142, blue: 97, x: 14, y: 16, imageName: ':angel:' },
  { red: 191, green: 169, blue: 117, x: 14, y: 17, imageName: ':scream:' },
  {
    red: 56,
    green: 36,
    blue: 26,
    x: 14,
    y: 18,
    imageName: ':boy::skin-tone-5:',
  },
  { red: 88, green: 73, blue: 124, x: 14, y: 19, imageName: ':unicorn:' },
  {
    red: 87,
    green: 61,
    blue: 50,
    x: 14,
    y: 20,
    imageName: ':horse_racing::skin-tone-5:',
  },
  {
    red: 135,
    green: 142,
    blue: 106,
    x: 14,
    y: 21,
    imageName: ':house_with_garden:',
  },
  {
    red: 85,
    green: 94,
    blue: 101,
    x: 14,
    y: 22,
    imageName: ':mouse_three_button:',
  },
  { red: 94, green: 70, blue: 136, x: 14, y: 23, imageName: ':milky_way:' },
  { red: 130, green: 66, blue: 31, x: 14, y: 24, imageName: ':lollipop:' },
  { red: 171, green: 162, blue: 113, x: 14, y: 25, imageName: ':cold_sweat:' },
  { red: 205, green: 171, blue: 102, x: 14, y: 26, imageName: ':dvd:' },
  { red: 165, green: 173, blue: 179, x: 14, y: 27, imageName: ':full_moon:' },
  { red: 156, green: 111, blue: 85, x: 14, y: 28, imageName: ':hamburger:' },
  { red: 136, green: 101, blue: 82, x: 14, y: 29, imageName: ':art:' },
  {
    red: 98,
    green: 66,
    blue: 101,
    x: 14,
    y: 30,
    imageName: ':ok_woman::skin-tone-5:',
  },
  { red: 82, green: 149, blue: 201, x: 14, y: 31, imageName: ':koko:' },
  { red: 84, green: 61, blue: 75, x: 14, y: 32, imageName: ':aerial_tramway:' },
  {
    red: 103,
    green: 100,
    blue: 89,
    x: 14,
    y: 33,
    imageName: ':pray::skin-tone-2:',
  },
  { red: 59, green: 16, blue: 15, x: 14, y: 34, imageName: ':rose:' },
  { red: 94, green: 33, blue: 29, x: 14, y: 35, imageName: ':boom:' },
  {
    red: 95,
    green: 116,
    blue: 106,
    x: 14,
    y: 36,
    imageName: ':airplane_arriving:',
  },
  { red: 106, green: 102, blue: 94, x: 14, y: 37, imageName: ':penguin:' },
  { red: 161, green: 131, blue: 93, x: 14, y: 38, imageName: ':couple_mm:' },
  { red: 166, green: 170, blue: 176, x: 14, y: 39, imageName: ':timer:' },
  { red: 120, green: 89, blue: 41, x: 14, y: 40, imageName: ':unlock:' },
  { red: 128, green: 140, blue: 154, x: 14, y: 41, imageName: ':post_office:' },
  { red: 81, green: 44, blue: 36, x: 14, y: 42, imageName: ':watermelon:' },
  { red: 46, green: 49, blue: 52, x: 14, y: 43, imageName: ':fencer:' },
  { red: 108, green: 71, blue: 42, x: 14, y: 44, imageName: ':chipmunk:' },
  { red: 165, green: 148, blue: 84, x: 14, y: 45, imageName: ':joy:' },
  { red: 113, green: 112, blue: 119, x: 14, y: 46, imageName: ':mouse:' },
  { red: 201, green: 154, blue: 61, x: 14, y: 47, imageName: ':yum:' },
  { red: 46, green: 34, blue: 16, x: 14, y: 48, imageName: ':hammer:' },
  { red: 153, green: 76, blue: 89, x: 14, y: 49, imageName: ':heartpulse:' },
  { red: 102, green: 56, blue: 62, x: 15, y: 0, imageName: ':no_bicycles:' },
  {
    red: 95,
    green: 100,
    blue: 104,
    x: 15,
    y: 1,
    imageName: ':bath::skin-tone-1:',
  },
  { red: 169, green: 166, blue: 142, x: 15, y: 2, imageName: ':hotel:' },
  { red: 59, green: 43, blue: 19, x: 15, y: 3, imageName: ':pick:' },
  { red: 95, green: 65, blue: 11, x: 15, y: 4, imageName: ':carrot:' },
  {
    red: 193,
    green: 142,
    blue: 123,
    x: 15,
    y: 5,
    imageName: ':notebook_with_decorative_cover:',
  },
  {
    red: 125,
    green: 106,
    blue: 45,
    x: 15,
    y: 6,
    imageName: ':left_facing_fist:',
  },
  {
    red: 96,
    green: 60,
    blue: 44,
    x: 15,
    y: 7,
    imageName: ':woman::skin-tone-4:',
  },
  { red: 51, green: 54, blue: 52, x: 15, y: 8, imageName: ':flashlight:' },
  { red: 145, green: 123, blue: 52, x: 15, y: 9, imageName: ':vulcan:' },
  { red: 36, green: 41, blue: 44, x: 15, y: 10, imageName: ':movie_camera:' },
  { red: 62, green: 69, blue: 74, x: 15, y: 11, imageName: ':file_cabinet:' },
  { red: 218, green: 157, blue: 88, x: 15, y: 12, imageName: ':family_wwg:' },
  { red: 95, green: 144, blue: 180, x: 15, y: 13, imageName: ':customs:' },
  {
    red: 97,
    green: 145,
    blue: 181,
    x: 15,
    y: 14,
    imageName: ':passport_control:',
  },
  { red: 185, green: 105, blue: 92, x: 15, y: 15, imageName: ':drum:' },
  { red: 150, green: 105, blue: 112, x: 15, y: 16, imageName: ':rice:' },
  { red: 148, green: 121, blue: 51, x: 15, y: 17, imageName: ':punch:' },
  { red: 87, green: 69, blue: 55, x: 15, y: 18, imageName: ':v::skin-tone-3:' },
  {
    red: 139,
    green: 62,
    blue: 69,
    x: 15,
    y: 19,
    imageName: ':japanese_goblin:',
  },
  {
    red: 89,
    green: 63,
    blue: 51,
    x: 15,
    y: 20,
    imageName: ':horse_racing::skin-tone-2:',
  },
  { red: 144, green: 56, blue: 69, x: 15, y: 21, imageName: ':broken_heart:' },
  {
    red: 32,
    green: 36,
    blue: 32,
    x: 15,
    y: 22,
    imageName: ':runner::skin-tone-3:',
  },
  { red: 149, green: 129, blue: 85, x: 15, y: 23, imageName: ':moneybag:' },
  { red: 198, green: 157, blue: 57, x: 15, y: 24, imageName: ':slight_smile:' },
  {
    red: 110,
    green: 104,
    blue: 102,
    x: 15,
    y: 25,
    imageName: ':japanese_castle:',
  },
  {
    red: 139,
    green: 108,
    blue: 39,
    x: 15,
    y: 26,
    imageName: ':hatched_chick:',
  },
  {
    red: 117,
    green: 103,
    blue: 95,
    x: 15,
    y: 27,
    imageName: ':ok_hand::skin-tone-1:',
  },
  { red: 146, green: 142, blue: 150, x: 15, y: 28, imageName: ':books:' },
  {
    red: 95,
    green: 67,
    blue: 46,
    x: 15,
    y: 29,
    imageName: ':ear::skin-tone-4:',
  },
  { red: 202, green: 161, blue: 59, x: 15, y: 30, imageName: ':no_mouth:' },
  { red: 66, green: 63, blue: 62, x: 15, y: 31, imageName: ':smoking:' },
  { red: 90, green: 26, blue: 35, x: 15, y: 32, imageName: ':pushpin:' },
  { red: 192, green: 162, blue: 68, x: 15, y: 33, imageName: ':fist:' },
  { red: 17, green: 20, blue: 22, x: 15, y: 34, imageName: ':spider:' },
  {
    red: 123,
    green: 53,
    blue: 62,
    x: 15,
    y: 35,
    imageName: ':flower_playing_cards:',
  },
  {
    red: 171,
    green: 116,
    blue: 112,
    x: 15,
    y: 36,
    imageName: ':santa::skin-tone-4:',
  },
  { red: 176, green: 82, blue: 95, x: 15, y: 37, imageName: ':name_badge:' },
  { red: 162, green: 181, blue: 195, x: 15, y: 38, imageName: ':newspaper:' },
  { red: 73, green: 85, blue: 95, x: 15, y: 39, imageName: ':eye:' },
  { red: 86, green: 25, blue: 33, x: 15, y: 40, imageName: ':shinto_shrine:' },
  { red: 93, green: 66, blue: 30, x: 15, y: 41, imageName: ':trumpet:' },
  { red: 115, green: 96, blue: 145, x: 15, y: 42, imageName: ':purple_heart:' },
  {
    red: 151,
    green: 116,
    blue: 40,
    x: 15,
    y: 43,
    imageName: ':tropical_fish:',
  },
  { red: 57, green: 71, blue: 82, x: 15, y: 44, imageName: ':ring:' },
  { red: 87, green: 88, blue: 84, x: 15, y: 45, imageName: ':satellite:' },
  { red: 66, green: 74, blue: 81, x: 15, y: 46, imageName: ':third_place:' },
  { red: 98, green: 159, blue: 205, x: 15, y: 47, imageName: ':fast_forward:' },
  { red: 204, green: 167, blue: 70, x: 15, y: 48, imageName: ':smirk_cat:' },
  { red: 140, green: 115, blue: 179, x: 15, y: 49, imageName: ':imp:' },
  {
    red: 87,
    green: 64,
    blue: 51,
    x: 16,
    y: 0,
    imageName: ':older_man::skin-tone-5:',
  },
  {
    red: 55,
    green: 36,
    blue: 27,
    x: 16,
    y: 1,
    imageName: ':nose::skin-tone-5:',
  },
  { red: 125, green: 100, blue: 37, x: 16, y: 2, imageName: ':trident:' },
  { red: 160, green: 152, blue: 148, x: 16, y: 3, imageName: ':rainbow:' },
  { red: 107, green: 94, blue: 64, x: 16, y: 4, imageName: ':bulb:' },
  {
    red: 156,
    green: 132,
    blue: 93,
    x: 16,
    y: 5,
    imageName: ':massage::skin-tone-2:',
  },
  { red: 122, green: 111, blue: 120, x: 16, y: 6, imageName: ':ambulance:' },
  {
    red: 115,
    green: 95,
    blue: 48,
    x: 16,
    y: 7,
    imageName: ':construction_worker::skin-tone-4:',
  },
  { red: 224, green: 73, blue: 92, x: 16, y: 8, imageName: ':u5408:' },
  {
    red: 87,
    green: 103,
    blue: 68,
    x: 16,
    y: 9,
    imageName: ':articulated_lorry:',
  },
  {
    red: 103,
    green: 69,
    blue: 49,
    x: 16,
    y: 10,
    imageName: ':boy::skin-tone-4:',
  },
  {
    red: 49,
    green: 108,
    blue: 154,
    x: 16,
    y: 11,
    imageName: ':open_file_folder:',
  },
  {
    red: 42,
    green: 48,
    blue: 52,
    x: 16,
    y: 12,
    imageName: ':busts_in_silhouette:',
  },
  {
    red: 32,
    green: 43,
    blue: 28,
    x: 16,
    y: 13,
    imageName: ':selfie::skin-tone-5:',
  },
  { red: 101, green: 104, blue: 102, x: 16, y: 14, imageName: ':bath:' },
  { red: 90, green: 111, blue: 100, x: 16, y: 15, imageName: ':trolleybus:' },
  {
    red: 79,
    green: 91,
    blue: 91,
    x: 16,
    y: 16,
    imageName: ':rowboat::skin-tone-2:',
  },
  { red: 189, green: 150, blue: 53, x: 16, y: 17, imageName: ':persevere:' },
  { red: 164, green: 172, blue: 178, x: 16, y: 18, imageName: ':volleyball:' },
  { red: 95, green: 80, blue: 58, x: 16, y: 19, imageName: ':womans_hat:' },
  { red: 31, green: 73, blue: 104, x: 16, y: 20, imageName: ':dolphin:' },
  { red: 81, green: 35, blue: 28, x: 16, y: 21, imageName: ':cherries:' },
  { red: 197, green: 161, blue: 101, x: 16, y: 22, imageName: ':family:' },
  {
    red: 148,
    green: 110,
    blue: 66,
    x: 16,
    y: 23,
    imageName: ':person_with_pouting_face:',
  },
  {
    red: 100,
    green: 67,
    blue: 19,
    x: 16,
    y: 24,
    imageName: ':high_brightness:',
  },
  {
    red: 88,
    green: 63,
    blue: 52,
    x: 16,
    y: 25,
    imageName: ':horse_racing::skin-tone-1:',
  },
  {
    red: 102,
    green: 69,
    blue: 70,
    x: 16,
    y: 26,
    imageName: ':massage::skin-tone-4:',
  },
  {
    red: 66,
    green: 43,
    blue: 32,
    x: 16,
    y: 27,
    imageName: ':ear::skin-tone-5:',
  },
  { red: 163, green: 122, blue: 92, x: 16, y: 28, imageName: ':raising_hand:' },
  { red: 212, green: 142, blue: 63, x: 16, y: 29, imageName: ':kiss_ww:' },
  { red: 122, green: 119, blue: 129, x: 16, y: 30, imageName: ':snowboarder:' },
  { red: 130, green: 131, blue: 132, x: 16, y: 31, imageName: ':rice_ball:' },
  { red: 190, green: 138, blue: 113, x: 16, y: 32, imageName: ':santa:' },
  { red: 61, green: 69, blue: 75, x: 16, y: 33, imageName: ':musical_score:' },
  { red: 59, green: 41, blue: 30, x: 16, y: 34, imageName: ':scorpion:' },
  { red: 129, green: 109, blue: 46, x: 16, y: 35, imageName: ':call_me:' },
  {
    red: 143,
    green: 131,
    blue: 85,
    x: 16,
    y: 36,
    imageName: ':oncoming_taxi:',
  },
  {
    red: 91,
    green: 137,
    blue: 65,
    x: 16,
    y: 37,
    imageName: ':nauseated_face:',
  },
  {
    red: 106,
    green: 89,
    blue: 109,
    x: 16,
    y: 38,
    imageName: ':information_desk_person::skin-tone-1:',
  },
  { red: 105, green: 125, blue: 139, x: 16, y: 39, imageName: ':minibus:' },
  {
    red: 101,
    green: 123,
    blue: 139,
    x: 16,
    y: 40,
    imageName: ':bullettrain_front:',
  },
  { red: 16, green: 18, blue: 20, x: 16, y: 41, imageName: ':top:' },
  {
    red: 170,
    green: 137,
    blue: 214,
    x: 16,
    y: 42,
    imageName: ':place_of_worship:',
  },
  { red: 129, green: 82, blue: 75, x: 16, y: 43, imageName: ':bento:' },
  { red: 73, green: 58, blue: 44, x: 16, y: 44, imageName: ':wilted_rose:' },
  { red: 79, green: 98, blue: 63, x: 16, y: 45, imageName: ':pear:' },
  {
    red: 195,
    green: 149,
    blue: 58,
    x: 16,
    y: 46,
    imageName: ':kissing_heart:',
  },
  { red: 130, green: 115, blue: 122, x: 16, y: 47, imageName: ':dart:' },
  { red: 33, green: 33, blue: 31, x: 16, y: 48, imageName: ':bomb:' },
  {
    red: 53,
    green: 32,
    blue: 24,
    x: 16,
    y: 49,
    imageName: ':girl::skin-tone-5:',
  },
  {
    red: 68,
    green: 89,
    blue: 108,
    x: 17,
    y: 0,
    imageName: ':swimmer::skin-tone-5:',
  },
  { red: 46, green: 44, blue: 62, x: 17, y: 1, imageName: ':tophat:' },
  { red: 95, green: 73, blue: 36, x: 17, y: 2, imageName: ':dizzy:' },
  { red: 33, green: 55, blue: 20, x: 17, y: 3, imageName: ':lizard:' },
  { red: 19, green: 21, blue: 23, x: 17, y: 4, imageName: ':electric_plug:' },
  {
    red: 35,
    green: 45,
    blue: 30,
    x: 17,
    y: 5,
    imageName: ':selfie::skin-tone-4:',
  },
  { red: 78, green: 47, blue: 16, x: 17, y: 6, imageName: ':violin:' },
  {
    red: 158,
    green: 168,
    blue: 174,
    x: 17,
    y: 7,
    imageName: ':page_facing_up:',
  },
  {
    red: 69,
    green: 71,
    blue: 74,
    x: 17,
    y: 8,
    imageName: ':sleeping_accommodation:',
  },
  { red: 57, green: 85, blue: 33, x: 17, y: 9, imageName: ':deciduous_tree:' },
  {
    red: 78,
    green: 80,
    blue: 86,
    x: 17,
    y: 10,
    imageName: ':bow::skin-tone-4:',
  },
  { red: 162, green: 146, blue: 128, x: 17, y: 11, imageName: ':cake:' },
  { red: 246, green: 174, blue: 80, x: 17, y: 12, imageName: ':vs:' },
  {
    red: 95,
    green: 115,
    blue: 106,
    x: 17,
    y: 13,
    imageName: ':airplane_departure:',
  },
  { red: 181, green: 151, blue: 58, x: 17, y: 14, imageName: ':money_mouth:' },
  { red: 104, green: 85, blue: 58, x: 17, y: 15, imageName: ':inbox_tray:' },
  {
    red: 126,
    green: 85,
    blue: 84,
    x: 17,
    y: 16,
    imageName: ':massage::skin-tone-3:',
  },
  { red: 94, green: 34, blue: 40, x: 17, y: 17, imageName: ':tongue:' },
  {
    red: 90,
    green: 72,
    blue: 117,
    x: 17,
    y: 18,
    imageName: ':womans_clothes:',
  },
  {
    red: 143,
    green: 116,
    blue: 95,
    x: 17,
    y: 19,
    imageName: ':older_man::skin-tone-3:',
  },
  { red: 227, green: 157, blue: 57, x: 17, y: 20, imageName: ':orange_book:' },
  { red: 146, green: 65, blue: 68, x: 17, y: 21, imageName: ':heartbeat:' },
  { red: 71, green: 54, blue: 58, x: 17, y: 22, imageName: ':thermometer:' },
  { red: 50, green: 60, blue: 60, x: 17, y: 23, imageName: ':bicyclist:' },
  {
    red: 75,
    green: 86,
    blue: 90,
    x: 17,
    y: 24,
    imageName: ':rowboat::skin-tone-5:',
  },
  { red: 53, green: 59, blue: 30, x: 17, y: 25, imageName: ':palm_tree:' },
  { red: 95, green: 107, blue: 114, x: 17, y: 26, imageName: ':radio:' },
  {
    red: 54,
    green: 58,
    blue: 60,
    x: 17,
    y: 27,
    imageName: ':eye_in_speech_bubble:',
  },
  { red: 83, green: 37, blue: 43, x: 17, y: 28, imageName: ':no_pedestrians:' },
  { red: 57, green: 32, blue: 24, x: 17, y: 29, imageName: ':mans_shoe:' },
  {
    red: 117,
    green: 93,
    blue: 72,
    x: 17,
    y: 30,
    imageName: ':ear::skin-tone-3:',
  },
  { red: 122, green: 110, blue: 92, x: 17, y: 31, imageName: ':curry:' },
  {
    red: 164,
    green: 140,
    blue: 129,
    x: 17,
    y: 32,
    imageName: ':bride_with_veil::skin-tone-4:',
  },
  {
    red: 146,
    green: 30,
    blue: 47,
    x: 17,
    y: 33,
    imageName: ':izakaya_lantern:',
  },
  { red: 48, green: 19, blue: 24, x: 17, y: 34, imageName: ':round_pushpin:' },
  { red: 60, green: 102, blue: 133, x: 17, y: 35, imageName: ':loop:' },
  {
    red: 93,
    green: 101,
    blue: 106,
    x: 17,
    y: 36,
    imageName: ':incoming_envelope:',
  },
  {
    red: 100,
    green: 80,
    blue: 63,
    x: 17,
    y: 37,
    imageName: ':ok_hand::skin-tone-3:',
  },
  {
    red: 146,
    green: 129,
    blue: 80,
    x: 17,
    y: 38,
    imageName: ':person_with_blond_hair::skin-tone-1:',
  },
  {
    red: 90,
    green: 85,
    blue: 89,
    x: 17,
    y: 39,
    imageName: ':champagne_glass:',
  },
  { red: 70, green: 42, blue: 34, x: 17, y: 40, imageName: ':amphora:' },
  { red: 172, green: 137, blue: 54, x: 17, y: 41, imageName: ':rofl:' },
  { red: 87, green: 18, blue: 26, x: 17, y: 42, imageName: ':x:' },
  {
    red: 146,
    green: 36,
    blue: 43,
    x: 17,
    y: 43,
    imageName: ':school_satchel:',
  },
  { red: 182, green: 144, blue: 51, x: 17, y: 44, imageName: ':tired_face:' },
  { red: 125, green: 42, blue: 47, x: 17, y: 45, imageName: ':ping_pong:' },
  { red: 72, green: 64, blue: 72, x: 17, y: 46, imageName: ':metro:' },
  { red: 50, green: 93, blue: 125, x: 17, y: 47, imageName: ':calling:' },
  { red: 50, green: 59, blue: 66, x: 17, y: 48, imageName: ':camera:' },
  { red: 150, green: 136, blue: 101, x: 17, y: 49, imageName: ':yen:' },
  { red: 148, green: 79, blue: 91, x: 18, y: 0, imageName: ':mushroom:' },
  {
    red: 106,
    green: 90,
    blue: 69,
    x: 18,
    y: 1,
    imageName: ':open_hands::skin-tone-2:',
  },
  {
    red: 154,
    green: 195,
    blue: 231,
    x: 18,
    y: 2,
    imageName: ':carousel_horse:',
  },
  { red: 70, green: 64, blue: 62, x: 18, y: 3, imageName: ':oden:' },
  { red: 104, green: 98, blue: 90, x: 18, y: 4, imageName: ':dolls:' },
  { red: 76, green: 81, blue: 85, x: 18, y: 5, imageName: ':sound:' },
  { red: 141, green: 116, blue: 180, x: 18, y: 6, imageName: ':smiling_imp:' },
  { red: 101, green: 90, blue: 83, x: 18, y: 7, imageName: ':v::skin-tone-1:' },
  { red: 35, green: 56, blue: 71, x: 18, y: 8, imageName: ':headphones:' },
  {
    red: 51,
    green: 20,
    blue: 25,
    x: 18,
    y: 9,
    imageName: ':small_red_triangle_down:',
  },
  { red: 165, green: 78, blue: 51, x: 18, y: 10, imageName: ':volcano:' },
  { red: 85, green: 93, blue: 105, x: 18, y: 11, imageName: ':ice_cream:' },
  { red: 102, green: 109, blue: 115, x: 18, y: 12, imageName: ':wolf:' },
  { red: 137, green: 35, blue: 44, x: 18, y: 13, imageName: ':apple:' },
  {
    red: 72,
    green: 85,
    blue: 95,
    x: 18,
    y: 14,
    imageName: ':cop::skin-tone-1:',
  },
  {
    red: 107,
    green: 94,
    blue: 97,
    x: 18,
    y: 15,
    imageName: ':mailbox_with_mail:',
  },
  {
    red: 108,
    green: 95,
    blue: 88,
    x: 18,
    y: 16,
    imageName: ':open_hands::skin-tone-1:',
  },
  {
    red: 44,
    green: 48,
    blue: 53,
    x: 18,
    y: 17,
    imageName: ':weight_lifter::skin-tone-1:',
  },
  {
    red: 181,
    green: 155,
    blue: 119,
    x: 18,
    y: 18,
    imageName: ':fist::skin-tone-2:',
  },
  {
    red: 72,
    green: 51,
    blue: 35,
    x: 18,
    y: 19,
    imageName: ':sign_of_the_horns::skin-tone-4:',
  },
  {
    red: 129,
    green: 111,
    blue: 61,
    x: 18,
    y: 20,
    imageName: ':construction_worker::skin-tone-2:',
  },
  {
    red: 25,
    green: 33,
    blue: 32,
    x: 18,
    y: 21,
    imageName: ':walking::skin-tone-1:',
  },
  { red: 17, green: 36, blue: 51, x: 18, y: 22, imageName: ':zzz:' },
  { red: 57, green: 51, blue: 26, x: 18, y: 23, imageName: ':tanabata_tree:' },
  { red: 189, green: 150, blue: 55, x: 18, y: 24, imageName: ':astonished:' },
  { red: 201, green: 132, blue: 40, x: 18, y: 25, imageName: ':accept:' },
  { red: 87, green: 68, blue: 43, x: 18, y: 26, imageName: ':french_bread:' },
  {
    red: 107,
    green: 93,
    blue: 71,
    x: 18,
    y: 27,
    imageName: ':point_up_2::skin-tone-2:',
  },
  { red: 47, green: 97, blue: 135, x: 18, y: 28, imageName: ':cyclone:' },
  { red: 94, green: 82, blue: 87, x: 18, y: 29, imageName: ':mailbox:' },
  { red: 122, green: 101, blue: 95, x: 18, y: 30, imageName: ':truck:' },
  {
    red: 115,
    green: 169,
    blue: 211,
    x: 18,
    y: 31,
    imageName: ':record_button:',
  },
  {
    red: 123,
    green: 133,
    blue: 141,
    x: 18,
    y: 32,
    imageName: ':last_quarter_moon:',
  },
  {
    red: 78,
    green: 98,
    blue: 104,
    x: 18,
    y: 33,
    imageName: ':surfer::skin-tone-4:',
  },
  { red: 93, green: 93, blue: 99, x: 18, y: 34, imageName: ':rabbit:' },
  { red: 60, green: 61, blue: 46, x: 18, y: 35, imageName: ':champagne:' },
  { red: 29, green: 69, blue: 99, x: 18, y: 36, imageName: ':jeans:' },
  { red: 68, green: 73, blue: 76, x: 18, y: 37, imageName: ':speaker:' },
  { red: 130, green: 88, blue: 26, x: 18, y: 38, imageName: ':bell:' },
  { red: 198, green: 148, blue: 65, x: 18, y: 39, imageName: ':blush:' },
  { red: 110, green: 134, blue: 103, x: 18, y: 40, imageName: ':dollar:' },
  { red: 188, green: 142, blue: 58, x: 18, y: 41, imageName: ':princess:' },
  { red: 114, green: 118, blue: 121, x: 18, y: 42, imageName: ':train2:' },
  {
    red: 98,
    green: 94,
    blue: 94,
    x: 18,
    y: 43,
    imageName: ':writing_hand::skin-tone-1:',
  },
  { red: 136, green: 104, blue: 181, x: 18, y: 44, imageName: ':u7a7a:' },
  {
    red: 68,
    green: 76,
    blue: 82,
    x: 18,
    y: 45,
    imageName: ':cop::skin-tone-3:',
  },
  {
    red: 202,
    green: 181,
    blue: 190,
    x: 18,
    y: 46,
    imageName: ':chart_with_upwards_trend:',
  },
  { red: 107, green: 130, blue: 98, x: 18, y: 47, imageName: ':battery:' },
  {
    red: 98,
    green: 100,
    blue: 103,
    x: 18,
    y: 48,
    imageName: ':bath::skin-tone-3:',
  },
  {
    red: 102,
    green: 68,
    blue: 53,
    x: 18,
    y: 49,
    imageName: ':princess::skin-tone-4:',
  },
  {
    red: 53,
    green: 62,
    blue: 73,
    x: 19,
    y: 0,
    imageName: ':bow::skin-tone-5:',
  },
  {
    red: 25,
    green: 29,
    blue: 31,
    x: 19,
    y: 1,
    imageName: ':currency_exchange:',
  },
  { red: 175, green: 144, blue: 217, x: 19, y: 2, imageName: ':menorah:' },
  { red: 215, green: 168, blue: 79, x: 19, y: 3, imageName: ':ledger:' },
  {
    red: 82,
    green: 54,
    blue: 37,
    x: 19,
    y: 4,
    imageName: ':clap::skin-tone-5:',
  },
  { red: 98, green: 148, blue: 147, x: 19, y: 5, imageName: ':earth_africa:' },
  {
    red: 93,
    green: 79,
    blue: 61,
    x: 19,
    y: 6,
    imageName: ':hand_with_index_and_middle_finger_crossed::skin-tone-2:',
  },
  {
    red: 11,
    green: 12,
    blue: 13,
    x: 19,
    y: 7,
    imageName: ':heavy_division_sign:',
  },
  {
    red: 84,
    green: 77,
    blue: 69,
    x: 19,
    y: 8,
    imageName: ':writing_hand::skin-tone-3:',
  },
  { red: 144, green: 79, blue: 62, x: 19, y: 9, imageName: ':boar:' },
  { red: 27, green: 31, blue: 34, x: 19, y: 10, imageName: ':black_heart:' },
  { red: 87, green: 42, blue: 50, x: 19, y: 11, imageName: ':airplane_small:' },
  { red: 201, green: 149, blue: 90, x: 19, y: 12, imageName: ':hamster:' },
  {
    red: 123,
    green: 103,
    blue: 57,
    x: 19,
    y: 13,
    imageName: ':construction_worker::skin-tone-3:',
  },
  { red: 89, green: 40, blue: 26, x: 19, y: 14, imageName: ':dancer:' },
  {
    red: 150,
    green: 115,
    blue: 114,
    x: 19,
    y: 15,
    imageName: ':mother_christmas::skin-tone-1:',
  },
  { red: 244, green: 158, blue: 46, x: 19, y: 16, imageName: ':u7533:' },
  {
    red: 95,
    green: 73,
    blue: 59,
    x: 19,
    y: 17,
    imageName: ':prince::skin-tone-4:',
  },
  {
    red: 156,
    green: 150,
    blue: 109,
    x: 19,
    y: 18,
    imageName: ':angel::skin-tone-2:',
  },
  {
    red: 115,
    green: 90,
    blue: 76,
    x: 19,
    y: 19,
    imageName: ':prince::skin-tone-3:',
  },
  {
    red: 76,
    green: 87,
    blue: 86,
    x: 19,
    y: 20,
    imageName: ':cop::skin-tone-2:',
  },
  { red: 55, green: 57, blue: 59, x: 19, y: 21, imageName: ':grey_question:' },
  { red: 45, green: 52, blue: 21, x: 19, y: 22, imageName: ':christmas_tree:' },
  { red: 189, green: 149, blue: 52, x: 19, y: 23, imageName: ':confounded:' },
  {
    red: 67,
    green: 72,
    blue: 73,
    x: 19,
    y: 24,
    imageName: ':sleuth_or_spy::skin-tone-2:',
  },
  { red: 140, green: 118, blue: 62, x: 19, y: 25, imageName: ':prince:' },
  {
    red: 63,
    green: 51,
    blue: 45,
    x: 19,
    y: 26,
    imageName: ':man_in_tuxedo::skin-tone-3:',
  },
  { red: 32, green: 21, blue: 45, x: 19, y: 27, imageName: ':prayer_beads:' },
  { red: 160, green: 131, blue: 55, x: 19, y: 28, imageName: ':sunglasses:' },
  { red: 128, green: 70, blue: 75, x: 19, y: 29, imageName: ':sushi:' },
  { red: 85, green: 83, blue: 108, x: 19, y: 30, imageName: ':trackball:' },
  {
    red: 110,
    green: 166,
    blue: 209,
    x: 19,
    y: 31,
    imageName: ':capital_abcd:',
  },
  { red: 151, green: 136, blue: 132, x: 19, y: 32, imageName: ':clipboard:' },
  { red: 120, green: 69, blue: 55, x: 19, y: 33, imageName: ':monkey:' },
  { red: 180, green: 135, blue: 119, x: 19, y: 34, imageName: ':ok_woman:' },
  { red: 59, green: 20, blue: 25, x: 19, y: 35, imageName: ':motor_scooter:' },
  {
    red: 145,
    green: 125,
    blue: 65,
    x: 19,
    y: 36,
    imageName: ':person_with_blond_hair::skin-tone-2:',
  },
  {
    red: 150,
    green: 162,
    blue: 200,
    x: 19,
    y: 37,
    imageName: ':construction_site:',
  },
  { red: 77, green: 82, blue: 85, x: 19, y: 38, imageName: ':checkered_flag:' },
  {
    red: 69,
    green: 59,
    blue: 52,
    x: 19,
    y: 39,
    imageName: ':writing_hand::skin-tone-4:',
  },
  { red: 99, green: 93, blue: 97, x: 19, y: 40, imageName: ':slot_machine:' },
  { red: 205, green: 167, blue: 70, x: 19, y: 41, imageName: ':kissing_cat:' },
  {
    red: 54,
    green: 35,
    blue: 36,
    x: 19,
    y: 42,
    imageName: ':guardsman::skin-tone-3:',
  },
  {
    red: 127,
    green: 82,
    blue: 81,
    x: 19,
    y: 43,
    imageName: ':haircut::skin-tone-3:',
  },
  {
    red: 166,
    green: 145,
    blue: 70,
    x: 19,
    y: 44,
    imageName: ':man::skin-tone-2:',
  },
  { red: 40, green: 46, blue: 50, x: 19, y: 45, imageName: ':spider_web:' },
  { red: 101, green: 73, blue: 32, x: 19, y: 46, imageName: ':microscope:' },
  { red: 88, green: 153, blue: 203, x: 19, y: 47, imageName: ':abc:' },
  { red: 117, green: 130, blue: 139, x: 19, y: 48, imageName: ':elephant:' },
  { red: 142, green: 122, blue: 51, x: 19, y: 49, imageName: ':hand_splayed:' },
  { red: 96, green: 98, blue: 103, x: 20, y: 0, imageName: ':control_knobs:' },
  {
    red: 118,
    green: 101,
    blue: 90,
    x: 20,
    y: 1,
    imageName: ':grandma::skin-tone-3:',
  },
  {
    red: 118,
    green: 98,
    blue: 122,
    x: 20,
    y: 2,
    imageName: ':raising_hand::skin-tone-1:',
  },
  { red: 129, green: 135, blue: 140, x: 20, y: 3, imageName: ':skull:' },
  { red: 108, green: 87, blue: 34, x: 20, y: 4, imageName: ':straight_ruler:' },
  { red: 127, green: 65, blue: 27, x: 20, y: 5, imageName: ':briefcase:' },
  {
    red: 84,
    green: 68,
    blue: 45,
    x: 20,
    y: 6,
    imageName: ':wave::skin-tone-5:',
  },
  { red: 166, green: 158, blue: 170, x: 20, y: 7, imageName: ':fish_cake:' },
  { red: 136, green: 70, blue: 50, x: 20, y: 8, imageName: ':chestnut:' },
  {
    red: 80,
    green: 92,
    blue: 100,
    x: 20,
    y: 9,
    imageName: ':new_moon_with_face:',
  },
  { red: 47, green: 100, blue: 140, x: 20, y: 10, imageName: ':ocean:' },
  { red: 37, green: 25, blue: 29, x: 20, y: 11, imageName: ':joystick:' },
  { red: 104, green: 59, blue: 65, x: 20, y: 12, imageName: ':underage:' },
  {
    red: 82,
    green: 78,
    blue: 57,
    x: 20,
    y: 13,
    imageName: ':man_with_turban::skin-tone-3:',
  },
  {
    red: 95,
    green: 84,
    blue: 77,
    x: 20,
    y: 14,
    imageName: ':hand_with_index_and_middle_finger_crossed::skin-tone-1:',
  },
  { red: 132, green: 143, blue: 151, x: 20, y: 15, imageName: ':cd:' },
  { red: 104, green: 110, blue: 111, x: 20, y: 16, imageName: ':baby_bottle:' },
  {
    red: 50,
    green: 53,
    blue: 48,
    x: 20,
    y: 17,
    imageName: ':weight_lifter::skin-tone-2:',
  },
  { red: 161, green: 146, blue: 63, x: 20, y: 18, imageName: ':wave:' },
  {
    red: 78,
    green: 102,
    blue: 114,
    x: 20,
    y: 19,
    imageName: ':mountain_cableway:',
  },
  { red: 97, green: 158, blue: 204, x: 20, y: 20, imageName: ':atm:' },
  {
    red: 196,
    green: 134,
    blue: 85,
    x: 20,
    y: 21,
    imageName: ':heart_eyes_cat:',
  },
  { red: 186, green: 150, blue: 59, x: 20, y: 22, imageName: ':grimacing:' },
  { red: 78, green: 73, blue: 27, x: 20, y: 23, imageName: ':pineapple:' },
  { red: 196, green: 132, blue: 91, x: 20, y: 24, imageName: ':couple_ww:' },
  { red: 113, green: 127, blue: 126, x: 20, y: 25, imageName: ':monorail:' },
  { red: 173, green: 140, blue: 59, x: 20, y: 26, imageName: ':nerd:' },
  {
    red: 136,
    green: 121,
    blue: 84,
    x: 20,
    y: 27,
    imageName: ':prince::skin-tone-2:',
  },
  {
    red: 107,
    green: 76,
    blue: 52,
    x: 20,
    y: 28,
    imageName: ':thumbup::skin-tone-4:',
  },
  {
    red: 80,
    green: 57,
    blue: 39,
    x: 20,
    y: 29,
    imageName: ':nose::skin-tone-4:',
  },
  { red: 200, green: 166, blue: 79, x: 20, y: 30, imageName: ':rolling_eyes:' },
  { red: 119, green: 63, blue: 70, x: 20, y: 31, imageName: ':wedding:' },
  { red: 50, green: 56, blue: 59, x: 20, y: 32, imageName: ':paperclip:' },
  {
    red: 120,
    green: 96,
    blue: 85,
    x: 20,
    y: 33,
    imageName: ':man_with_gua_pi_mao::skin-tone-1:',
  },
  { red: 135, green: 106, blue: 114, x: 20, y: 34, imageName: ':goal:' },
  { red: 104, green: 74, blue: 81, x: 20, y: 35, imageName: ':circus_tent:' },
  { red: 179, green: 144, blue: 116, x: 20, y: 36, imageName: ':bread:' },
  {
    red: 110,
    green: 94,
    blue: 72,
    x: 20,
    y: 37,
    imageName: ':nose::skin-tone-2:',
  },
  { red: 57, green: 59, blue: 61, x: 20, y: 38, imageName: ':cocktail:' },
  { red: 115, green: 96, blue: 75, x: 20, y: 39, imageName: ':custard:' },
  { red: 32, green: 56, blue: 74, x: 20, y: 40, imageName: ':athletic_shoe:' },
  {
    red: 101,
    green: 65,
    blue: 47,
    x: 20,
    y: 41,
    imageName: ':girl::skin-tone-4:',
  },
  {
    red: 61,
    green: 41,
    blue: 30,
    x: 20,
    y: 42,
    imageName: ':call_me_hand::skin-tone-5:',
  },
  { red: 64, green: 30, blue: 26, x: 20, y: 43, imageName: ':sandal:' },
  { red: 89, green: 96, blue: 102, x: 20, y: 44, imageName: ':loud_sound:' },
  { red: 156, green: 97, blue: 79, x: 20, y: 45, imageName: ':monkey_face:' },
  { red: 157, green: 128, blue: 101, x: 20, y: 46, imageName: ':ramen:' },
  { red: 34, green: 54, blue: 22, x: 20, y: 47, imageName: ':crocodile:' },
  {
    red: 53,
    green: 54,
    blue: 45,
    x: 20,
    y: 48,
    imageName: ':person_with_ball::skin-tone-2:',
  },
  { red: 63, green: 85, blue: 97, x: 20, y: 49, imageName: ':motorboat:' },
  { red: 170, green: 141, blue: 78, x: 21, y: 0, imageName: ':city_sunset:' },
  { red: 226, green: 90, blue: 107, x: 21, y: 1, imageName: ':sos:' },
  { red: 77, green: 99, blue: 72, x: 21, y: 2, imageName: ':clapper:' },
  { red: 198, green: 158, blue: 57, x: 21, y: 3, imageName: ':frowning:' },
  {
    red: 23,
    green: 17,
    blue: 17,
    x: 21,
    y: 4,
    imageName: ':male_dancer::skin-tone-4:',
  },
  {
    red: 80,
    green: 104,
    blue: 111,
    x: 21,
    y: 5,
    imageName: ':surfer::skin-tone-1:',
  },
  { red: 102, green: 161, blue: 207, x: 21, y: 6, imageName: ':eject:' },
  { red: 33, green: 37, blue: 27, x: 21, y: 7, imageName: ':walking:' },
  { red: 194, green: 161, blue: 90, x: 21, y: 8, imageName: ':family_mmg:' },
  {
    red: 46,
    green: 28,
    blue: 30,
    x: 21,
    y: 9,
    imageName: ':guardsman::skin-tone-5:',
  },
  {
    red: 159,
    green: 114,
    blue: 68,
    x: 21,
    y: 10,
    imageName: ':two_women_holding_hands:',
  },
  {
    red: 17,
    green: 24,
    blue: 23,
    x: 21,
    y: 11,
    imageName: ':walking::skin-tone-5:',
  },
  { red: 58, green: 86, blue: 108, x: 21, y: 12, imageName: ':second_place:' },
  {
    red: 44,
    green: 43,
    blue: 45,
    x: 21,
    y: 13,
    imageName: ':weight_lifter::skin-tone-3:',
  },
  {
    red: 40,
    green: 51,
    blue: 36,
    x: 21,
    y: 14,
    imageName: ':selfie::skin-tone-1:',
  },
  { red: 98, green: 60, blue: 20, x: 21, y: 15, imageName: ':postal_horn:' },
  { red: 162, green: 161, blue: 137, x: 21, y: 16, imageName: ':school:' },
  {
    red: 97,
    green: 88,
    blue: 100,
    x: 21,
    y: 17,
    imageName: ':haircut::skin-tone-1:',
  },
  { red: 31, green: 35, blue: 37, x: 21, y: 18, imageName: ':pen_fountain:' },
  {
    red: 106,
    green: 87,
    blue: 116,
    x: 21,
    y: 19,
    imageName: ':no_good::skin-tone-1:',
  },
  {
    red: 53,
    green: 34,
    blue: 45,
    x: 21,
    y: 20,
    imageName: ':person_frowning::skin-tone-5:',
  },
  { red: 75, green: 85, blue: 92, x: 21, y: 21, imageName: ':mag_right:' },
  { red: 120, green: 161, blue: 96, x: 21, y: 22, imageName: ':tennis:' },
  { red: 99, green: 85, blue: 65, x: 21, y: 23, imageName: ':v::skin-tone-2:' },
  { red: 78, green: 79, blue: 78, x: 21, y: 24, imageName: ':cooking:' },
  { red: 190, green: 145, blue: 63, x: 21, y: 25, imageName: ':cat:' },
  { red: 39, green: 59, blue: 21, x: 21, y: 26, imageName: ':evergreen_tree:' },
  { red: 109, green: 115, blue: 119, x: 21, y: 27, imageName: ':ticket:' },
  {
    red: 91,
    green: 84,
    blue: 79,
    x: 21,
    y: 28,
    imageName: ':pray::skin-tone-3:',
  },
  { red: 144, green: 151, blue: 156, x: 21, y: 29, imageName: ':e_mail:' },
  {
    red: 55,
    green: 80,
    blue: 95,
    x: 21,
    y: 30,
    imageName: ':night_with_stars:',
  },
  {
    red: 92,
    green: 45,
    blue: 51,
    x: 21,
    y: 31,
    imageName: ':non_potable_water:',
  },
  { red: 62, green: 97, blue: 125, x: 21, y: 32, imageName: ':gem:' },
  {
    red: 75,
    green: 88,
    blue: 94,
    x: 21,
    y: 33,
    imageName: ':satellite_orbital:',
  },
  {
    red: 53,
    green: 35,
    blue: 29,
    x: 21,
    y: 34,
    imageName: ':princess::skin-tone-5:',
  },
  {
    red: 33,
    green: 35,
    blue: 36,
    x: 21,
    y: 35,
    imageName: ':grey_exclamation:',
  },
  { red: 69, green: 41, blue: 46, x: 21, y: 36, imageName: ':syringe:' },
  {
    red: 24,
    green: 27,
    blue: 30,
    x: 21,
    y: 37,
    imageName: ':bust_in_silhouette:',
  },
  {
    red: 38,
    green: 52,
    blue: 52,
    x: 21,
    y: 38,
    imageName: ':golfer::skin-tone-2:',
  },
  { red: 55, green: 41, blue: 78, x: 21, y: 39, imageName: ':eggplant:' },
  { red: 21, green: 33, blue: 42, x: 21, y: 40, imageName: ':eyeglasses:' },
  {
    red: 160,
    green: 137,
    blue: 120,
    x: 21,
    y: 41,
    imageName: ':clap::skin-tone-1:',
  },
  { red: 89, green: 63, blue: 50, x: 21, y: 42, imageName: ':horse_racing:' },
  {
    red: 138,
    green: 118,
    blue: 91,
    x: 21,
    y: 43,
    imageName: ':back_of_hand::skin-tone-2:',
  },
  {
    red: 55,
    green: 49,
    blue: 49,
    x: 21,
    y: 44,
    imageName: ':pray::skin-tone-5:',
  },
  {
    red: 165,
    green: 104,
    blue: 84,
    x: 21,
    y: 45,
    imageName: ':speak_no_evil:',
  },
  {
    red: 57,
    green: 39,
    blue: 38,
    x: 21,
    y: 46,
    imageName: ':guardsman::skin-tone-2:',
  },
  {
    red: 103,
    green: 139,
    blue: 150,
    x: 21,
    y: 47,
    imageName: ':mountain_snow:',
  },
  { red: 120, green: 93, blue: 33, x: 21, y: 48, imageName: ':baby_chick:' },
  {
    red: 138,
    green: 93,
    blue: 27,
    x: 21,
    y: 49,
    imageName: ':large_orange_diamond:',
  },
  { red: 39, green: 27, blue: 10, x: 22, y: 0, imageName: ':hockey:' },
  {
    red: 120,
    green: 90,
    blue: 67,
    x: 22,
    y: 1,
    imageName: ':older_man::skin-tone-4:',
  },
  {
    red: 186,
    green: 162,
    blue: 60,
    x: 22,
    y: 2,
    imageName: ':woman::skin-tone-2:',
  },
  { red: 99, green: 160, blue: 206, x: 22, y: 3, imageName: ':repeat:' },
  {
    red: 57,
    green: 39,
    blue: 41,
    x: 22,
    y: 4,
    imageName: ':guardsman::skin-tone-1:',
  },
  { red: 54, green: 67, blue: 37, x: 22, y: 5, imageName: ':herb:' },
  {
    red: 74,
    green: 78,
    blue: 81,
    x: 22,
    y: 6,
    imageName: ':last_quarter_moon_with_face:',
  },
  {
    red: 40,
    green: 50,
    blue: 58,
    x: 22,
    y: 7,
    imageName: ':bicyclist::skin-tone-5:',
  },
  {
    red: 129,
    green: 129,
    blue: 120,
    x: 22,
    y: 8,
    imageName: ':white_sun_rain_cloud:',
  },
  { red: 88, green: 75, blue: 40, x: 22, y: 9, imageName: ':taxi:' },
  {
    red: 102,
    green: 162,
    blue: 207,
    x: 22,
    y: 10,
    imageName: ':put_litter_in_its_place:',
  },
  { red: 199, green: 158, blue: 57, x: 22, y: 11, imageName: ':neutral_face:' },
  { red: 113, green: 34, blue: 38, x: 22, y: 12, imageName: ':strawberry:' },
  { red: 132, green: 68, blue: 41, x: 22, y: 13, imageName: ':pill:' },
  { red: 85, green: 38, blue: 44, x: 22, y: 14, imageName: ':no_smoking:' },
  { red: 180, green: 107, blue: 79, x: 22, y: 15, imageName: ':peach:' },
  {
    red: 85,
    green: 131,
    blue: 157,
    x: 22,
    y: 16,
    imageName: ':running_shirt_with_sash:',
  },
  {
    red: 150,
    green: 169,
    blue: 177,
    x: 22,
    y: 17,
    imageName: ':department_store:',
  },
  {
    red: 93,
    green: 97,
    blue: 101,
    x: 22,
    y: 18,
    imageName: ':bath::skin-tone-5:',
  },
  { red: 127, green: 153, blue: 175, x: 22, y: 19, imageName: ':hospital:' },
  {
    red: 46,
    green: 93,
    blue: 129,
    x: 22,
    y: 20,
    imageName: ':large_blue_diamond:',
  },
  {
    red: 30,
    green: 39,
    blue: 37,
    x: 22,
    y: 21,
    imageName: ':runner::skin-tone-1:',
  },
  { red: 117, green: 127, blue: 135, x: 22, y: 22, imageName: ':rhino:' },
  {
    red: 116,
    green: 78,
    blue: 76,
    x: 22,
    y: 23,
    imageName: ':mother_christmas::skin-tone-5:',
  },
  {
    red: 62,
    green: 46,
    blue: 56,
    x: 22,
    y: 24,
    imageName: ':haircut::skin-tone-5:',
  },
  {
    red: 109,
    green: 98,
    blue: 91,
    x: 22,
    y: 25,
    imageName: ':point_up_2::skin-tone-1:',
  },
  {
    red: 97,
    green: 66,
    blue: 47,
    x: 22,
    y: 26,
    imageName: ':man_with_gua_pi_mao::skin-tone-4:',
  },
  { red: 49, green: 33, blue: 24, x: 22, y: 27, imageName: ':v::skin-tone-5:' },
  { red: 148, green: 153, blue: 151, x: 22, y: 28, imageName: ':office:' },
  { red: 77, green: 115, blue: 145, x: 22, y: 29, imageName: ':cruise_ship:' },
  { red: 141, green: 145, blue: 151, x: 22, y: 30, imageName: ':card_box:' },
  { red: 146, green: 55, blue: 67, x: 22, y: 31, imageName: ':door:' },
  { red: 221, green: 162, blue: 80, x: 22, y: 32, imageName: ':family_wwgb:' },
  { red: 194, green: 140, blue: 39, x: 22, y: 33, imageName: ':hugging:' },
  {
    red: 119,
    green: 92,
    blue: 71,
    x: 22,
    y: 34,
    imageName: ':man_with_gua_pi_mao::skin-tone-2:',
  },
  {
    red: 122,
    green: 80,
    blue: 99,
    x: 22,
    y: 35,
    imageName: ':no_good::skin-tone-3:',
  },
  {
    red: 130,
    green: 117,
    blue: 112,
    x: 22,
    y: 36,
    imageName: ':bride_with_veil::skin-tone-5:',
  },
  {
    red: 94,
    green: 157,
    blue: 205,
    x: 22,
    y: 37,
    imageName: ':signal_strength:',
  },
  {
    red: 88,
    green: 62,
    blue: 51,
    x: 22,
    y: 38,
    imageName: ':horse_racing::skin-tone-3:',
  },
  {
    red: 23,
    green: 25,
    blue: 26,
    x: 22,
    y: 39,
    imageName: ':man_in_business_suit_levitating::skin-tone-1:',
  },
  { red: 79, green: 95, blue: 47, x: 22, y: 40, imageName: ':couch:' },
  {
    red: 101,
    green: 87,
    blue: 67,
    x: 22,
    y: 41,
    imageName: ':reversed_hand_with_middle_finger_extended::skin-tone-2:',
  },
  { red: 28, green: 19, blue: 43, x: 22, y: 42, imageName: ':bikini:' },
  {
    red: 219,
    green: 203,
    blue: 143,
    x: 22,
    y: 43,
    imageName: ':bride_with_veil::skin-tone-2:',
  },
  {
    red: 115,
    green: 80,
    blue: 52,
    x: 22,
    y: 44,
    imageName: ':clap::skin-tone-4:',
  },
  {
    red: 151,
    green: 158,
    blue: 136,
    x: 22,
    y: 45,
    imageName: ':mountain_railway:',
  },
  { red: 97, green: 129, blue: 77, x: 22, y: 46, imageName: ':melon:' },
  {
    red: 10,
    green: 12,
    blue: 13,
    x: 22,
    y: 47,
    imageName: ':heavy_dollar_sign:',
  },
  {
    red: 159,
    green: 138,
    blue: 85,
    x: 22,
    y: 48,
    imageName: ':hatching_chick:',
  },
  { red: 95, green: 65, blue: 21, x: 22, y: 49, imageName: ':tiger2:' },
  { red: 97, green: 13, blue: 26, x: 23, y: 0, imageName: ':100:' },
  { red: 85, green: 46, blue: 34, x: 23, y: 1, imageName: ':key:' },
  {
    red: 52,
    green: 34,
    blue: 45,
    x: 23,
    y: 2,
    imageName: ':person_with_pouting_face::skin-tone-5:',
  },
  {
    red: 101,
    green: 58,
    blue: 57,
    x: 23,
    y: 3,
    imageName: ':nail_care::skin-tone-3:',
  },
  { red: 67, green: 54, blue: 60, x: 23, y: 4, imageName: ':wine_glass:' },
  { red: 202, green: 165, blue: 70, x: 23, y: 5, imageName: ':pouting_cat:' },
  { red: 48, green: 73, blue: 92, x: 23, y: 6, imageName: ':butterfly:' },
  {
    red: 158,
    green: 105,
    blue: 106,
    x: 23,
    y: 7,
    imageName: ':santa::skin-tone-5:',
  },
  { red: 79, green: 53, blue: 15, x: 23, y: 8, imageName: ':low_brightness:' },
  { red: 190, green: 81, blue: 97, x: 23, y: 9, imageName: ':purse:' },
  { red: 170, green: 120, blue: 43, x: 23, y: 10, imageName: ':cheese:' },
  { red: 71, green: 79, blue: 84, x: 23, y: 11, imageName: ':paperclips:' },
  { red: 89, green: 125, blue: 137, x: 23, y: 12, imageName: ':mountain:' },
  { red: 105, green: 109, blue: 113, x: 23, y: 13, imageName: ':dog:' },
  { red: 48, green: 73, blue: 34, x: 23, y: 14, imageName: ':cucumber:' },
  { red: 82, green: 94, blue: 102, x: 23, y: 15, imageName: ':new_moon:' },
  {
    red: 65,
    green: 71,
    blue: 76,
    x: 23,
    y: 16,
    imageName: ':sleuth_or_spy::skin-tone-1:',
  },
  { red: 91, green: 97, blue: 100, x: 23, y: 17, imageName: ':bathtub:' },
  { red: 160, green: 123, blue: 73, x: 23, y: 18, imageName: ':massage:' },
  {
    red: 145,
    green: 148,
    blue: 150,
    x: 23,
    y: 19,
    imageName: ':white_square_button:',
  },
  {
    red: 123,
    green: 105,
    blue: 81,
    x: 23,
    y: 20,
    imageName: ':call_me_hand::skin-tone-2:',
  },
  { red: 187, green: 149, blue: 57, x: 23, y: 21, imageName: ':smile:' },
  { red: 186, green: 148, blue: 56, x: 23, y: 22, imageName: ':grinning:' },
  {
    red: 109,
    green: 74,
    blue: 89,
    x: 23,
    y: 23,
    imageName: ':raising_hand::skin-tone-4:',
  },
  { red: 111, green: 119, blue: 124, x: 23, y: 24, imageName: ':moyai:' },
  { red: 92, green: 82, blue: 52, x: 23, y: 25, imageName: ':construction:' },
  { red: 23, green: 25, blue: 23, x: 23, y: 26, imageName: ':levitate:' },
  {
    red: 135,
    green: 90,
    blue: 68,
    x: 23,
    y: 27,
    imageName: ':boy::skin-tone-3:',
  },
  { red: 132, green: 81, blue: 68, x: 23, y: 28, imageName: ':poop:' },
  {
    red: 55,
    green: 37,
    blue: 58,
    x: 23,
    y: 29,
    imageName: ':expecting_woman::skin-tone-4:',
  },
  { red: 98, green: 159, blue: 206, x: 23, y: 30, imageName: ':free:' },
  {
    red: 82,
    green: 40,
    blue: 41,
    x: 23,
    y: 31,
    imageName: ':nail_care::skin-tone-5:',
  },
  {
    red: 77,
    green: 52,
    blue: 35,
    x: 23,
    y: 32,
    imageName: ':raised_hands::skin-tone-5:',
  },
  { red: 41, green: 75, blue: 100, x: 23, y: 33, imageName: ':iphone:' },
  { red: 225, green: 83, blue: 101, x: 23, y: 34, imageName: ':u5272:' },
  { red: 244, green: 160, blue: 49, x: 23, y: 35, imageName: ':u6709:' },
  { red: 225, green: 84, blue: 102, x: 23, y: 36, imageName: ':u6e80:' },
  { red: 40, green: 51, blue: 30, x: 23, y: 37, imageName: ':selfie:' },
  {
    red: 159,
    green: 105,
    blue: 56,
    x: 23,
    y: 38,
    imageName: ':closed_lock_with_key:',
  },
  {
    red: 122,
    green: 97,
    blue: 75,
    x: 23,
    y: 39,
    imageName: ':punch::skin-tone-3:',
  },
  {
    red: 188,
    green: 157,
    blue: 71,
    x: 23,
    y: 40,
    imageName: ':disappointed_relieved:',
  },
  { red: 87, green: 52, blue: 45, x: 23, y: 41, imageName: ':clock:' },
  {
    red: 145,
    green: 128,
    blue: 113,
    x: 23,
    y: 42,
    imageName: ':raised_hands::skin-tone-1:',
  },
  { red: 112, green: 30, blue: 32, x: 23, y: 43, imageName: ':bird:' },
  { red: 58, green: 39, blue: 33, x: 23, y: 44, imageName: ':guardsman:' },
  { red: 81, green: 75, blue: 78, x: 23, y: 45, imageName: ':mouse2:' },
  { red: 107, green: 10, blue: 25, x: 23, y: 46, imageName: ':crab:' },
  { red: 210, green: 160, blue: 80, x: 23, y: 47, imageName: ':family_mwgb:' },
  {
    red: 52,
    green: 35,
    blue: 26,
    x: 23,
    y: 48,
    imageName: ':open_hands::skin-tone-5:',
  },
  {
    red: 95,
    green: 68,
    blue: 57,
    x: 23,
    y: 49,
    imageName: ':house_abandoned:',
  },
  { red: 123, green: 86, blue: 94, x: 24, y: 0, imageName: ':pig2:' },
  {
    red: 149,
    green: 112,
    blue: 103,
    x: 24,
    y: 1,
    imageName: ':mother_christmas::skin-tone-2:',
  },
  { red: 62, green: 65, blue: 67, x: 24, y: 2, imageName: ':crescent_moon:' },
  {
    red: 49,
    green: 91,
    blue: 123,
    x: 24,
    y: 3,
    imageName: ':diamond_shape_with_a_dot_inside:',
  },
  {
    red: 101,
    green: 137,
    blue: 93,
    x: 24,
    y: 4,
    imageName: ':mountain_bicyclist:',
  },
  { red: 162, green: 150, blue: 154, x: 24, y: 5, imageName: ':black_joker:' },
  {
    red: 38,
    green: 34,
    blue: 32,
    x: 24,
    y: 6,
    imageName: ':man_in_tuxedo::skin-tone-5:',
  },
  {
    red: 100,
    green: 136,
    blue: 99,
    x: 24,
    y: 7,
    imageName: ':mountain_bicyclist::skin-tone-1:',
  },
  { red: 147, green: 106, blue: 124, x: 24, y: 8, imageName: ':train:' },
  { red: 145, green: 109, blue: 60, x: 24, y: 9, imageName: ':pancakes:' },
  { red: 31, green: 47, blue: 22, x: 24, y: 10, imageName: ':seedling:' },
  {
    red: 112,
    green: 99,
    blue: 92,
    x: 24,
    y: 11,
    imageName: ':nose::skin-tone-1:',
  },
  { red: 183, green: 179, blue: 190, x: 24, y: 12, imageName: ':bar_chart:' },
  { red: 53, green: 37, blue: 74, x: 24, y: 13, imageName: ':bug:' },
  {
    red: 185,
    green: 162,
    blue: 69,
    x: 24,
    y: 14,
    imageName: ':princess::skin-tone-2:',
  },
  { red: 30, green: 9, blue: 5, x: 24, y: 15, imageName: ':feet:' },
  {
    red: 124,
    green: 100,
    blue: 118,
    x: 24,
    y: 16,
    imageName: ':convenience_store:',
  },
  { red: 35, green: 42, blue: 47, x: 24, y: 17, imageName: ':vhs:' },
  {
    red: 235,
    green: 150,
    blue: 164,
    x: 24,
    y: 18,
    imageName: ':heart_decoration:',
  },
  { red: 62, green: 35, blue: 41, x: 24, y: 19, imageName: ':telescope:' },
  {
    red: 40,
    green: 53,
    blue: 67,
    x: 24,
    y: 20,
    imageName: ':fishing_pole_and_fish:',
  },
  {
    red: 99,
    green: 78,
    blue: 29,
    x: 24,
    y: 21,
    imageName: ':person_with_blond_hair::skin-tone-5:',
  },
  { red: 180, green: 141, blue: 54, x: 24, y: 22, imageName: ':boy:' },
  { red: 133, green: 138, blue: 142, x: 24, y: 23, imageName: ':ghost:' },
  {
    red: 88,
    green: 57,
    blue: 62,
    x: 24,
    y: 24,
    imageName: ':person_frowning::skin-tone-4:',
  },
  {
    red: 107,
    green: 86,
    blue: 68,
    x: 24,
    y: 25,
    imageName: ':call_me_hand::skin-tone-3:',
  },
  {
    red: 158,
    green: 95,
    blue: 28,
    x: 24,
    y: 26,
    imageName: ':jack_o_lantern:',
  },
  { red: 206, green: 155, blue: 70, x: 24, y: 27, imageName: ':family_mmgg:' },
  {
    red: 73,
    green: 49,
    blue: 76,
    x: 24,
    y: 28,
    imageName: ':no_good::skin-tone-5:',
  },
  {
    red: 168,
    green: 162,
    blue: 159,
    x: 24,
    y: 29,
    imageName: ':bride_with_veil::skin-tone-1:',
  },
  {
    red: 42,
    green: 24,
    blue: 18,
    x: 24,
    y: 30,
    imageName: ':woman::skin-tone-5:',
  },
  {
    red: 186,
    green: 152,
    blue: 138,
    x: 24,
    y: 31,
    imageName: ':bride_with_veil::skin-tone-3:',
  },
  { red: 86, green: 94, blue: 93, x: 24, y: 32, imageName: ':station:' },
  { red: 80, green: 146, blue: 195, x: 24, y: 33, imageName: ':blue_book:' },
  { red: 44, green: 42, blue: 38, x: 24, y: 34, imageName: ':mortar_board:' },
  {
    red: 119,
    green: 95,
    blue: 75,
    x: 24,
    y: 35,
    imageName: ':raised_hand_with_fingers_splayed::skin-tone-3:',
  },
  {
    red: 123,
    green: 133,
    blue: 140,
    x: 24,
    y: 36,
    imageName: ':first_quarter_moon:',
  },
  {
    red: 143,
    green: 121,
    blue: 90,
    x: 24,
    y: 37,
    imageName: ':raised_hands::skin-tone-2:',
  },
  { red: 91, green: 155, blue: 204, x: 24, y: 38, imageName: ':pause_button:' },
  { red: 152, green: 115, blue: 84, x: 24, y: 39, imageName: ':mrs_claus:' },
  {
    red: 38,
    green: 45,
    blue: 34,
    x: 24,
    y: 40,
    imageName: ':runner::skin-tone-2:',
  },
  { red: 119, green: 143, blue: 112, x: 24, y: 41, imageName: ':tea:' },
  { red: 60, green: 52, blue: 68, x: 24, y: 42, imageName: ':sparkles:' },
  { red: 125, green: 44, blue: 57, x: 24, y: 43, imageName: ':boxing_glove:' },
  { red: 119, green: 46, blue: 57, x: 24, y: 44, imageName: ':kiss:' },
  { red: 91, green: 87, blue: 60, x: 24, y: 45, imageName: ':tropical_drink:' },
  {
    red: 95,
    green: 116,
    blue: 131,
    x: 24,
    y: 46,
    imageName: ':swimmer::skin-tone-3:',
  },
  {
    red: 188,
    green: 138,
    blue: 140,
    x: 24,
    y: 47,
    imageName: ':santa::skin-tone-1:',
  },
  { red: 110, green: 125, blue: 137, x: 24, y: 48, imageName: ':cloud_snow:' },
  {
    red: 138,
    green: 118,
    blue: 90,
    x: 24,
    y: 49,
    imageName: ':raised_hand::skin-tone-2:',
  },
  {
    red: 144,
    green: 158,
    blue: 169,
    x: 25,
    y: 0,
    imageName: ':notepad_spiral:',
  },
  {
    red: 111,
    green: 100,
    blue: 95,
    x: 25,
    y: 1,
    imageName: ':angel::skin-tone-4:',
  },
  { red: 186, green: 147, blue: 51, x: 25, y: 2, imageName: ':dizzy_face:' },
  {
    red: 74,
    green: 98,
    blue: 116,
    x: 25,
    y: 3,
    imageName: ':bullettrain_side:',
  },
  {
    red: 115,
    green: 122,
    blue: 126,
    x: 25,
    y: 4,
    imageName: ':musical_keyboard:',
  },
  { red: 100, green: 87, blue: 82, x: 25, y: 5, imageName: ':chicken:' },
  { red: 114, green: 32, blue: 44, x: 25, y: 6, imageName: ':tickets:' },
  { red: 71, green: 35, blue: 25, x: 25, y: 7, imageName: ':shrimp:' },
  { red: 211, green: 159, blue: 100, x: 25, y: 8, imageName: ':family_wwb:' },
  {
    red: 92,
    green: 128,
    blue: 90,
    x: 25,
    y: 9,
    imageName: ':mountain_bicyclist::skin-tone-5:',
  },
  { red: 173, green: 177, blue: 190, x: 25, y: 10, imageName: ':beach:' },
  {
    red: 58,
    green: 61,
    blue: 44,
    x: 25,
    y: 11,
    imageName: ':man_with_turban::skin-tone-5:',
  },
  { red: 171, green: 167, blue: 158, x: 25, y: 12, imageName: ':pencil:' },
  { red: 62, green: 18, blue: 24, x: 25, y: 13, imageName: ':high_heel:' },
  { red: 185, green: 164, blue: 95, x: 25, y: 14, imageName: ':joy_cat:' },
  {
    red: 72,
    green: 94,
    blue: 101,
    x: 25,
    y: 15,
    imageName: ':surfer::skin-tone-5:',
  },
  { red: 42, green: 40, blue: 36, x: 25, y: 16, imageName: ':video_game:' },
  { red: 99, green: 54, blue: 40, x: 25, y: 17, imageName: ':camel:' },
  {
    red: 123,
    green: 95,
    blue: 49,
    x: 25,
    y: 18,
    imageName: ':man_with_gua_pi_mao:',
  },
  {
    red: 103,
    green: 92,
    blue: 86,
    x: 25,
    y: 19,
    imageName: ':reversed_hand_with_middle_finger_extended::skin-tone-1:',
  },
  {
    red: 104,
    green: 83,
    blue: 65,
    x: 25,
    y: 20,
    imageName: ':left_fist::skin-tone-3:',
  },
  { red: 57, green: 64, blue: 70, x: 25, y: 21, imageName: ':police_car:' },
  { red: 112, green: 97, blue: 41, x: 25, y: 22, imageName: ':point_up_2:' },
];
