<div class="big-boy">
  <div class="vertical">
    <div class="horizontal">
      <ngx-dropzone
        *ngIf="!rendering && !rendered && !loading"
        class="drop-image-here"
        (change)="onSelect($event)"
      >
        <ngx-dropzone-label>
          Drop an image here to Emojify™
          <br />
          <mat-icon>cloud_upload</mat-icon>
        </ngx-dropzone-label>
      </ngx-dropzone>

      <div *ngIf="rendering" class="drop-image-here">
        <mat-spinner></mat-spinner>
      </div>

      <div *ngIf="loading">
        <mat-spinner></mat-spinner>
      </div>
    </div>
    <div class="horizontal">
      <canvas id="scaled-image" width="500" height="500"></canvas>
    </div>
    <div *ngIf="rendered" class="horizontal">
      <button mat-raised-button color="secondary" id="reset" (click)="reset()">
        Reset
      </button>
      <button mat-raised-button color="primary" id="copy" (click)="copy()">
        Copy High Res
      </button>
      <button mat-raised-button color="accent" id="copy" (click)="download()">
        Download High Res
      </button>
    </div>
  </div>
</div>

<canvas id="sprite-map" width="1000" height="1000"></canvas>

<canvas id="rendered-image" width="2000" height="2000"></canvas>
<img id="image" />
<canvas id="canvas" width="100" height="100"></canvas>

<app-all-the-svgs></app-all-the-svgs>
